import React from "react";
import styles from "./styles.module.css";
import delhivery from "../../assets/ticker/delhivery.svg";
import bluedart from "../../assets/ticker/bluedart.svg";
import snowman from "../../assets/ticker/snowman.svg";
import ecomexpress from "../../assets/ticker/ecomexpress.svg";
import dhl from "../../assets/ticker/dhl.svg";
import mahindra from "../../assets/ticker/mahindra.svg";
import trackon from "../../assets/ticker/trackon.svg";
import rivigo from "../../assets/ticker/rivigo.svg";
import ampl from "../../assets/ticker/ampl.svg";
import loadshare from "../../assets/ticker/loadshare.svg";
import xpressbees from "../../assets/ticker/xpressbees.svg";
import leap from "../../assets/ticker/leap.svg";
import apllogigistic from "../../assets/ticker/apllogistic.svg";
import konoike from "../../assets/ticker/konoike.svg";
import delhiprisons from "../../assets/ticker/delhiprisons.svg";
import delhipolice from "../../assets/ticker/delhipolice.svg";
import adb from "../../assets/ticker/adb.svg";
import flipkart from "../../assets/ticker/flipkart.svg";
import hyperpure from "../../assets/ticker/hyperpure.svg";
import zomato from "../../assets/ticker/zomato.svg";
import reliance from "../../assets/ticker/reliance.svg";
import myntra from "../../assets/ticker/myntra.svg";
import bigbasket from "../../assets/ticker/bigbasket.svg";
import adityabirla from "../../assets/ticker/adityabirla.svg";
import amtek from "../../assets/ticker/amtek.svg";
import eicher from "../../assets/ticker/eicher.svg";
import dixon from "../../assets/ticker/dixon.svg";
import siemens from "../../assets/ticker/siemens.svg";
import sleepwell from "../../assets/ticker/sleepwell.svg";
import samsung from "../../assets/ticker/samsung.svg";
import lg from "../../assets/ticker/lg.svg";
import hnm from "../../assets/ticker/hnm.svg";
import haier from "../../assets/ticker/haier.svg";
import vahdam from "../../assets/ticker/vahdam.svg";
import devans from "../../assets/ticker/devans.svg";
import morde from "../../assets/ticker/morde.svg";
import superplum from "../../assets/ticker/superplum.svg";
import mundhra from "../../assets/ticker/mundhra.svg";
import maersk from "../../assets/ticker/maersk.svg";
import cmacgm from "../../assets/ticker/cmacgm.svg";
import hindterminal from "../../assets/ticker/hindterminal.svg";
import hpcsl from "../../assets/ticker/hpcsl.svg";
import storemygood from "../../assets/ticker/storemygood.svg";
import bharatpetroleum from "../../assets/ticker/bharatpetroleum.svg";
import pepfuels from "../../assets/ticker/pepfuels.svg";
import organickashmir from "../../assets/ticker/organickashmir.svg";
import willmar from "../../assets/ticker/willmar.svg";
import icicibank from "../../assets/ticker/icicibank.svg";
import samsungsds from "../../assets/ticker/samsungsds.svg";
import jio from "../../assets/ticker/jio.svg";
import cdac from "../../assets/ticker/cdac.svg";
import brij from "../../assets/ticker/brij.svg";
import caratlane from "../../assets/ticker/caratlane.svg";
import sec from '../../assets/ticker/sec.svg';
import labor from '../../assets/ticker/labor.svg'
import cycle from '../../assets/ticker/cycle.svg'
import accuracy from '../../assets/ticker/accuracy.svg'

const Ticker = () => {
	const logos = [
		delhivery,
		bluedart,
		snowman,
		ecomexpress,
		dhl,
		mahindra,
		trackon,
		rivigo,
		ampl,
		loadshare,
		xpressbees,
		leap,
		apllogigistic,
		konoike,
		delhiprisons,
		delhipolice,
		adb,
		flipkart,
		hyperpure,
		zomato,
		reliance,
		myntra,
		bigbasket,
		adityabirla,
		amtek,
		eicher,
		dixon,
		siemens,
		sleepwell,
		samsung,
		lg,
		hnm,
		haier,
		vahdam,
		devans,
		morde,
		superplum,
		mundhra,
		maersk,
		cmacgm,
		hindterminal,
		hpcsl,
		storemygood,
		bharatpetroleum,
		pepfuels,
		organickashmir,
		willmar,
		icicibank,
		samsungsds,
		jio,
		cdac,
		brij,
		caratlane,
	];

    const metricsData = [
        {
		  image: accuracy,
          title: "99%",
          body: "Accuracy improved through reduced errors",
        },
        {
		  image: labor,
          title: "20-30%",
          body: "Increased labor productivity through task optimisation",
        },
        {
		  image: cycle,
          title: "25-30%",
          body: "Reduced order cycle times",
        },
        {
		  image: sec,
          title: "1 Sec",
          body: "To find any warehouse asset",
        },
      ];

	return (
		<>
			{/* ------ Metrics ------ */}
			{/* <div className={styles.metrics_container}>
				{metricsData.map((item, i) => {
					return (
						<div key={i} className={styles.metrics_box}>
							<p className={styles.metrics_title}>{item?.title}</p>
							<p className={styles.metrics_body}>{item?.body}</p>
						</div>
					);
				})}
			</div> */}
			<div className={styles.metrics_container}>
				{metricsData.map((item, i) => {
					return (
						<div key={i} className={styles.metrics_box} style={{border:'2px solid #ff7700'}}>
							<div className={styles.extraborder}></div>
							<div className={styles.contentBox}>
								<img src={item?.image} alt="" className={styles.tileImage} />
								<p className={styles.metrics_title}>{item?.title}</p>
								<p className={styles.metrics_body}>{item?.body}</p>
							</div>
						</div>
					);
				})}
			</div>

            {/* ------ Ticker section ------ */}
			<div className={styles.ticker_wrapper}>
				<div className={styles.ticker}>
					{[...logos, ...logos].map(
						(
							logo,
							index // Single duplication for seamless loop
						) => (
							<div className={styles["ticker-item"]} key={index}>
								<img src={logo} alt={`logo-${index}`} style={index % 2 === 0 ? { height: "75px" } : {}} />
							</div>
						)
					)}
				</div>
			</div>
		</>
	);
};

export default Ticker;
