import React from 'react';
import styles from "./styles.module.css";
import { StyledHeading } from '../../HelperComponents';
import logo from "../../assets/api/lynkit_logo.svg";
import desktopBacklines from "../../assets/api/backLine_desktop.svg";
import mobileBacklines from "../../assets/api/backLine_mobile.svg";
import tabletBacklines from '../../assets/api/tabletBacklines.svg'
import sync_purchase from '../../assets/api/sync_purchase.svg'
import inventory_list from '../../assets/api/inventory_list.svg'
import pickList from '../../assets/api/pickList.svg'
import stockAge from '../../assets/api/stockAge.svg'
import ASN from '../../assets/api/ASN.svg'
import stock_movement from '../../assets/api/stock_movement.svg'
import dispatchApi from '../../assets/api/dispatchApi.svg'
import syncInventory from '../../assets/api/syncInventory.svg'
import airCar from '../../assets/api/airCar.svg'
import courier from '../../assets/api/courier.svg'
import shopify from '../../assets/api/shopify.svg'
import erp from '../../assets/api/erp.svg'
import gst from '../../assets/api/gst.svg'
import digilocker from '../../assets/api/digiLocker.svg'
import wip from '../../assets/api/wip.svg'
import shipping from '../../assets/api/shipping.svg'


const Api = () => {

  const leftTiles = [
    {
      id: "sarathi",
      icon: sync_purchase,
      text: "Sync Purchase Orders (POs)",
    },
    {
      id: "pan",
      icon: inventory_list,
      text: "Get Inventory List API (Gate In Stock)",
    },
    {
      id: "digilocker",
      icon: pickList,
      text: "Pick List Generation API",
    },
    {
      id: "ulip",
      icon: stock_movement,
      text: "Stock Movement Logs API",
    },
    {
      id: "vahan",
      icon: ASN,
      text: "Create ASN (Advance Shipment Notice) API",
    },
    {
      id: "fois",
      icon: stockAge,
      text: "Stock Age, Batch & Expiry Tracking API",
    },
    {
      id: "fastag",
      icon: dispatchApi,
      text: "Dispatch Confirmation API (Gated Out Stock)",
    },
    {
      id: "goods",
      icon: syncInventory,
      text: "Sync Inventory Stock with ERP/SAP API",
    },
    
  ]
  const rightTiles = [
    {
      id: "nlds",
      icon: airCar,
      text: `Air Cargo`,
    },
    {
      id: "iecode",
      icon: courier,
      text: "Courier Integration",
    },
    {
      id: "ondc",
      icon: shipping,
      text: "Shipping Bill",
    },
    {
      id: "rthighway",
      icon: wip,
      text: "WIP Data Entry (used for ASN receiving from ERPs)",
    },
    {
      id: "cargo",
      icon: erp,
      text: "ERP",
    },
    {
      id: "custom",
      icon: gst,
      text: "GST",
    },
    {
      id: "acfi",
      icon: digilocker,
      text: "Digilocker",
    },
    {
      id: "pcs",

      icon: shopify,
      text: "Shopify",
    },
  ]

  return (

    <>
    <div  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop:'10px'
        }}>
        <StyledHeading text="Lynkit's API" />
    </div >
    <div className={styles.main_container}>

      <div className={styles.api_section}>
        <div className={styles.left_side}>
          <span className={`${styles.title} ${styles.left_title}`}>Lynkit Internal API</span>
          {leftTiles.map((tile, i) => {
            return (
              <div className={styles.api_box_container} id={styles[tile.id]}>
                <div className={styles.tile} style={{ background: "#eeeeee" }}>
                  <img src={tile.icon} />
                </div>
                <p className={styles.tile_text}>{tile.text}</p>
              </div>
            )
          })}
        </div>
        <div className={styles.right_side}>
          <span className={`${styles.title} ${styles.right_title}`}>Third Party APIs</span>
          {rightTiles.map((tile, i) => {
            return (
              <div className={styles.api_box_container} id={styles[tile.id]}>
                <div className={styles.tile} style={{ background: "#fafafa" }}>
                  <img src={tile.icon} />
                </div>
                <p className={styles.tile_text}>{tile.text}</p>
              </div>
            )
          })}
        </div>
        {/* lynkit logo */}
        <div className={styles.logo_container}>
          <img src={logo} />
        </div>
        {/* background line structure */}
        <div className={styles.desktop_backlines}>
          <img src={desktopBacklines} />
        </div>
        <div className={styles.tablet_backlines}>
          <img src={tabletBacklines} />
        </div>
        <div className={styles.mobile_backlines}>
          <img src={mobileBacklines} />
        </div>
      </div>
    </div>
    
    </>
  )
}

export default Api
