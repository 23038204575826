import React from "react";
import { StyledHeading } from "../../HelperComponents";
import mainStyles from "../../main.module.css";
import styles from "./styles.module.css";
import analyticsIcon from '../../assets/features/analyticsIcon.svg'
import automatedIcon from '../../assets/features/automatedIcon.svg'
import customizableIcon from '../../assets/features/customizableIcon.svg'
import digitaltwinIcon from '../../assets/features/digitaltwinIcon.svg'
import jobIcon from '../../assets/features/jobIcon.svg'
import realTimeImg from '../../assets/features/realTimeImg.svg'
import analyticsImg from '../../assets/features/analyticsImg.svg'
import automatedImg from '../../assets/features/automatedImg.svg'
import customizableImg from '../../assets/features/customizableImg.svg'
import digitaltwinImg from '../../assets/features/digitaltwinImg.svg'
import jobImg from '../../assets/features/jobImg.svg'

const Tab = ({ info, active, onClick }) => {
	return (
		<div className={styles.feature_tab} style={{ background: active ? "#FF7700" : "#FFFFFF" }} onClick={onClick}>
			<img src={info?.icon} />
			<span className={styles.title}>{info?.title}</span>
		</div>
	);
};

const Features = ({ current, setCurrent }) => {
	// const [current, setCurrent] = React.useState(selectedFeature);

	const features = [
			{
				title: "Real-Time Space Utilization & Optimization",
				icon: digitaltwinIcon,
				img: realTimeImg,
				text: `Lynkgrid offers highly visual tools, such as color-coded space utilization matrices, that provide real-time visibility into shelf, rack, and zone usage, ensuring optimal space management.`,
			},
			{
				title: "Digital Twin Visualization",
				icon: digitaltwinIcon,
				img: digitaltwinImg,
				text: `The system provides a digital twin of the warehouse, allowing for better planning, monitoring, and real-time job allocation. It is also more intuitive than many competitors.`,
			},
			{
				title: "Automated Inventory Tracking & FEFO Management",
				icon: automatedIcon,
				img: automatedImg,
				text: `Lynkgrid ensures accurate inventory management with RFID/BLE and FEFO tracking, which is crucial for pharma, food, and fast-moving sectors. It boosts efficiency by automating stock allocation to staging areas for faster dispatches and optimizing dock allocations for seamless operations, supporting the rapid demands of quick commerce and e-commerce.`,
			},
			{
				title: "Customizable Workflow",
				icon: customizableIcon,
				img: customizableImg,
				text: `Its highly customizable modules allow easy integration with existing systems (e.g., ERPs), offering flexibility across industries like manufacturing and 3PLs.`,
			},
			{
				title: "Comprehensive Job & Production Tracking",
				icon: jobIcon,
				img: jobImg,
				text: `Lynkgrid's ability to track job progress, automate task allocation, and manage production processes adds significant value, reducing manual errors and delays.`,
			},
			{
				title: "Analytics and Reporting",
				icon: analyticsIcon,
				img: analyticsImg,
				text: `Lynkgrid WMS provides a comprehensive reporting suite, including Inventory Summary, Space Utilization, Productivity Tracking, Stock Movement, FIFO/FEFO, Inventory Age Reports, and Kitting and Assembly visibility. It also supports Custom Reports for tailored insights, enabling data-driven decision-making and operational efficiency.`,
			},		
	];

	return (
		<div className={styles.software_features_section}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
                    marginTop: '25px'
				}}
			>
				<StyledHeading text="Software Features" />
			</div>
			<div className={`${styles.features_outer_container} ${styles.large_screens}`}>
				<div className={styles.feature_tabs}>
					{features?.map((feature, i) => {
						return <Tab info={feature} active={i === current ? true : false} onClick={() => setCurrent(i)} key={i} />;
					})}
				</div>

				<div className={styles.feature_content}>
					<div className={styles.text_content_sf}>
						<span>{features[current].title}</span>
						<p>{features[current].text}</p>
					</div>
					<div className={styles.img_content}>
						<img src={features[current].img} />
					</div>
				</div>
				<div className={styles.outline_container}>
					<button
						className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
						onClick={() => {
							window.open("https://lynkit.in/ContactUs", "_blank");
							// window.open("https://lynkit.in/ContactUs", '_self');
						}}
					>
						Get Started
					</button>
				</div>
			</div>
			<div className={`${styles["slider-container"]} ${styles.small_screens}`}>
				<div className={`${styles["slider-wrapper"]}`}>
					{features?.map((feature, i) => {
						return (
							<div className={`${styles["slider-item"]}`}>
								<div className={styles["title-row"]}>
									<img src={feature.icon} />
									<h3>{feature.title}</h3>
								</div>
								<hr style={{ border: "1px solid #ff7700", opacity: 1 }} />
								<p style={{ fontSize: "14px" }}>{feature.text}</p>
								<img src={feature?.img} alt="Geofencing" />
							</div>
						);
					})}
				</div>
			</div>

			<div className={styles.button_small_screen}>
				<button
					className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
					onClick={() => {
						window.open("https://lynkit.in/ContactUs", "_blank");
					}}
				>
					Request Demo
				</button>
			</div>
			<div className={styles.small_screens_button_outliner}></div>
		</div>
	);
};

export default Features;
