import React, { useState, useEffect } from "react";
import style from "./styles.module.css";
import { Link } from "react-router-dom";
import lynkit_logo from '../../assets/footer/lynkgridLogo.svg'
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Header = ({ setSelectedFeature }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [scrollingUp, setScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false); // Track if in mobile view
  const [parentUrl, setParentUrl] = useState(null);
  const [isInIframe, setIsInIframe] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the element specified by the hash
    if (location.hash) {
      const element = document.querySelector(location.hash.replace(/\/+/g, '\\/'));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      // If no hash, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 980); // Mobile if width is less than or equal to 980px
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };


  React.useEffect(() => {
    // Check if the page is loaded inside an iframe
    const checkIfInIframe = () => {
      try {
        setIsInIframe(window.self !== window.top);
      } catch (e) {
        // If accessing window.top throws an error, assume it's in an iframe
        setIsInIframe(true);
      }
    };

    checkIfInIframe();
  }, []);

  React.useEffect(() => {
    if (isInIframe) {
      // Listen for messages from the parent
      const handleMessage = (event) => {
        // Ensure the message is from a trusted origin (e.g., the parent app)
        if (!event.origin) {
          return; // Skip if the origin is not available
        }

        // Handle the message
        if (event.data.type === 'PARENT_URL_RESPONSE') {
          // Set the parent's URL in state
          setParentUrl(event.data.url);
        }
      };

      window.addEventListener('message', handleMessage);

      // Send a message to the parent to request its URL
      try {
        window.parent.postMessage({ type: 'REQUEST_PARENT_URL' }, '*');
      } catch (e) {
        console.error('Failed to communicate with the parent window:', e);
      }

      // Clean up the event listener
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, [isInIframe]);

  const handleButtonClick = () => {
    if (isInIframe && parentUrl) {
      window.top.location.href = `${parentUrl}login`;
    } else {
      window.location.href = 'https://warehouse.lynkgrid.com/';
    }
  };

  const handleLinkClick = () => {
    // Close the menu if it's open in mobile view
    if (isMobileView) {
      setIsOpen(false);
    }
  };

  const menuItems = [
    { label: "Home", link: "/warehouse-management", tgt: "_self" },
    {
      label: "Key Features",
      link: "/warehouse-management#features",
    },
    { label: "Use Cases", link: "/warehouse-management#usecases", tgt: "_self" },
    { label: "Hardware", link: "/warehouse-management#hardware-products", tgt: "_self" },
    {
      label: "About Us",
      link: "/About_us",
      tgt: "_blank",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollingUp(false);
      } else {
        setScrollingUp(true);
      }
      setLastScrollY(window.scrollY);
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <header
      className={`${style.header} ${scrollingUp ? style.showHeader : style.hideHeader
        } ${scrolled || isOpen ? style.scrolled : ""} ${isOpen ? style.menuOpen : ""
        }`}
    >
      <div className={style.container}>
        <div className={style.logo}>
          <Link to={"/warehouse-management#"} target="_self">
            <img src={lynkit_logo} alt="Lynkit Logo" />
          </Link>
        </div>
        <nav className={`${style.nav} ${isOpen ? style.open : ""}`}>
          <ul className={style["nav-list"]}>
            {menuItems.map((item, index) => (
              <li
                key={index}
                className={style["nav-item"]}
                onClick={handleLinkClick} // Close menu on click
              >
                {item.subItems ? (
                  // Render as a button if there are subItems
                  <button
                    className={style["nav-link"]}
                    onClick={() => toggleSubmenu(index)}
                  >
                    {item.label}
                  </button>
                ) : (
                  // Render as a Link for navigation
                  <Link
                    to={item.link}
                    className={style["nav-link"]}
                    target={item.tgt}
                    onClick={handleLinkClick} // Close menu on click
                  >
                    {item.label}
                  </Link>
                )}
                <div className={style.gradient_line}></div>
              </li>
            ))}
          </ul>
          <button className={style.mobilefilled_gray} id={style.logbtn} onClick={() => {
            window.open("https://warehouse.lynkgrid.com/", "_blank");
          }}>
            {/* <a href="" target="_blank" className={style.header_login_btn}> */}
            Login
            {/* </a> */}
          </button>
          <button
            className={style.filled_orange}
            id={style.contact}
            onClick={() => {
              window.open("https://www.lynkit.in/ContactUs", "_blank");
            }}
          >
            Request A Demo
          </button>
        </nav>

        {isOpen ? (
          <button className={style["hamburger"]} onClick={toggleMenu}>
            <span className={style.cross}>✖</span>
          </button>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
            <button className={style.mobilefilled_gray} onClick={(e) => {
              // window.open("https://wms.lynkgrid.com/signin", "_blank");
              e.preventDefault();
              handleButtonClick();
            }}>Login
              {/* <a href="https://wms.lynkgrid.com/signin" target="_blank" className={style.header_login_btn}>Login</a> */}
            </button>
            <button className={style["hamburger"]} onClick={toggleMenu}>
              <span className={style["hamburger-line"]}></span>
              <span className={style["hamburger-line"]}></span>
              <span className={style["hamburger-line"]}></span>
            </button>
          </div>

        )}


        <div className={style.action_btn_container}>
          <button
            className={style.filled_gray}
            onClick={(e) => {
              // window.open("https://wms.lynkgrid.com/signin", "_blank");
              e.preventDefault();
              handleButtonClick();
            }}
          >
            Login
          </button>
          <button
            className={style.filled_orange}
            onClick={() => {
              window.open("https://www.lynkit.in/ContactUs", "_blank");
            }}
          >
            Request A Demo
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
