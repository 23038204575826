import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import styles from './styles.module.css'

const Hexagon = ({ groupValue, onGroupValueChange }) => {
  const svgRef = useRef();
  const [selectedTriangles, setSelectedTriangles] = useState([]);

  useEffect(() => {
    let width;
    let height;
    let radius;
    if(window.innerWidth>465){
      width=500
      height=500
      radius=240
    }
    else{
      width=325
      height=325
      radius=155
    }
    // const height = 500;
    // const radius = 240;

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Create hexagon vertices
    const hexagon = d3.range(6).map((i) => {
      const angle = (i * 2 * Math.PI) / 6; // Calculate angle for each vertex
      return [radius * Math.sin(angle), -radius * Math.cos(angle)]; // [x, y]
    });

    // Draw the invisible hexagon (no fill and no stroke)
    svg.append('polygon')
      .attr('points', hexagon.join(' ')) // Join points into a string
      .attr('stroke', 'transparent') // Make the border invisible
      .attr('fill', 'transparent') // Make the hexagon itself invisible
      .style('pointer-events', 'all'); // Ensure it is clickable

    // Function to handle triangle click
    const handleTriangleClick = (i) => {
      let newGroupValue;
      if (i === 0 || i === 3) {
        newGroupValue = 0;
      } else if (i === 1 || i === 4) {
        newGroupValue = 1;
      } else if (i === 2 || i === 5) {
        newGroupValue = 2;
      }

      // Call the passed onGroupValueChange handler to update groupValue in the parent component
      onGroupValueChange(newGroupValue);

      setSelectedTriangles([i, (i + 3) % 6]); // Store the selected and opposite triangles
      console.log(selectedTriangles)
    };

    // Create 6 invisible triangles inside the hexagon
    const center = [0, 0]; // Center of the hexagon
    hexagon.forEach((point, i) => {
      const nextPoint = hexagon[(i + 1) % 6]; // Get the next vertex
      const triangle = svg.append('polygon')
        .attr('points', [
          center.join(','), // Center point
          point.join(','),  // Current vertex
          nextPoint.join(',') // Next vertex
        ].join(' '))
        .attr('stroke', 'transparent') // Make the borders invisible
        .attr('fill', 'transparent') // Make the triangles invisible
        .on('click', () => handleTriangleClick(i))  // Add click handler
        .style('pointer-events', 'all'); // Ensure the triangles are clickable

      // Optional: you can still style the selected triangles to indicate they're clicked
      triangle.style('fill', (d, j) => {
        if (selectedTriangles.includes(i) || selectedTriangles.includes((i + 3) % 6)) {
          return 'transparent'; // Highlight the selected triangle
        } else {
          return 'transparent'; // Keep non-selected triangles invisible
        }
      });
    });
  }, [selectedTriangles]);

  return (
    <div className={styles.svgContainer}>
      <svg ref={svgRef} style={{width:'auto'}} className={styles.hexSvg}></svg>
    </div>
  );
};

export default Hexagon;
