import React from "react";
import { GridContainer, GridItem, StyledHeading } from "../../HelperComponents";
import mainStyle from "../../main.module.css";
import styles from "./styles.module.css";
// import icon1 from "../../../../../assets/infoWindow/End.svg";
import img1 from "../../assets/asset_utilization.svg";
import antiTheft from "../../assets/anti_theft_sol.svg";
import targetQuicker from "../../assets/target_quicker.svg";
import safety from "../../assets/quickGlance/safety_new.svg";
// import flipkart from "../../../../../assets/homeimg/img9_flipkart.jpg";
// import delhivery from "../../../../../assets/homeimg/img7_delhivery.jpg";
// import blueDart from "../../../../../assets/homeimg/img6_blueDart.jpg";
// import dixon from '../../../../../assets/homeimg/img4_dixon.jpg';
// import mahindra from '../../../../../assets/homeimg/mahindraLogistics.png';
// import adiyabirla from '../../../../../assets/homeimg/img11_adiyabirla.jpg';
// import lg from '../../../../../assets/homeimg/img12_lg.jpg';
// import group from '../../../../../assets/homeimg/img2_group.jpg';
// import Cmacga from '../../../../../assets/homeimg/img13_cmacga.jfif'
// import ADB from '../../../../../assets/homeimg/img14_ADB.png'
import delhivery from "../../assets/quickGlance/delhivery.svg";
import bluedart from "../../assets/quickGlance/bluedart.svg";
import snowman from "../../assets/quickGlance/snowman.svg";
import ecomexpress from "../../assets/quickGlance/ecomexpress.svg";
import dhl from "../../assets/quickGlance/dhl.svg";
import mahindra from "../../assets/quickGlance/mahindra.svg";
import trackon from "../../assets/quickGlance/trackon.svg";
import rivigo from "../../assets/quickGlance/rivigo.svg";
import ampl from "../../assets/quickGlance/ampl.svg";
import loadshare from "../../assets/quickGlance/loadshare.svg";
import xpressbees from "../../assets/quickGlance/xpressbees.svg";
import leap from "../../assets/quickGlance/leap.svg";
import apllogigistic from "../../assets/quickGlance/apllogistic.svg";
import konoike from "../../assets/quickGlance/konoike.svg";
import delhiprisons from "../../assets/quickGlance/delhiprisons.svg";
import delhipolice from "../../assets/quickGlance/delhipolice.svg";
import adb from "../../assets/quickGlance/adb.svg";
import flipkart from "../../assets/quickGlance/flipkart.svg";
import hyperpure from "../../assets/quickGlance/hyperpure.svg";
import zomato from "../../assets/quickGlance/zomato.svg";
import reliance from "../../assets/quickGlance/reliance.svg";
import myntra from "../../assets/quickGlance/myntra.svg";
import bigbasket from "../../assets/quickGlance/bigbasket.svg";
import adityabirla from "../../assets/quickGlance/adityabirla.svg";
import amtek from "../../assets/quickGlance/amtek.svg";
import eicher from "../../assets/quickGlance/eicher.svg";
import dixon from "../../assets/quickGlance/dixon.svg";
import siemens from "../../assets/quickGlance/siemens.svg";
import sleepwell from "../../assets/quickGlance/sleepwell.svg";
import samsung from "../../assets/quickGlance/samsung.svg";
import lg from "../../assets/quickGlance/lg.svg";
import hnm from "../../assets/quickGlance/hnm.svg";
import haier from "../../assets/quickGlance/haier.svg";
import vahdam from "../../assets/quickGlance/vahdam.svg";
import devans from "../../assets/quickGlance/devans.svg";
import morde from "../../assets/quickGlance/morde.svg";
import superplum from "../../assets/quickGlance/superplum.svg";
import mundhra from "../../assets/quickGlance/mundhra.svg";
import maersk from "../../assets/quickGlance/maersk.svg";
import cmacgm from "../../assets/quickGlance/cmacgm.svg";
import hindterminal from "../../assets/quickGlance/hindterminal.svg";
import hpcsl from "../../assets/quickGlance/hpcsl.svg";
import storemygood from "../../assets/quickGlance/storemygood.svg";
import bharatpetroleum from "../../assets/quickGlance/bharatpetroleum.svg";
import pepfuels from "../../assets/quickGlance/pepfuels.svg";
import organickashmir from "../../assets/quickGlance/organickashmir.svg";
import willmar from "../../assets/quickGlance/willmar.svg";
import icicibank from "../../assets/quickGlance/icicibank.svg";
import samsungsds from "../../assets/quickGlance/samsungsds.svg";
import jio from "../../assets/quickGlance/jio.svg";
import cdac from "../../assets/quickGlance/cdac.svg";
import brij from "../../assets/quickGlance/brij.svg";
import caratlane from "../../assets/quickGlance/caratlane.svg";
const Card = ({ icon, title, description, className }) => {
  return (
    <div className={`${styles["card"]} ${className}`}>
      <div className={styles["card-icon"]}>
        <img src={icon} alt="icon" />
      </div>
      <div className={styles["card-content"]}>
        <h3 className={styles["card-title"]}>{title}</h3>
        <p className={styles["card-description"]}>{description}</p>
        {/* <a href="#" className={styles["card-link"]}>
          Read More <span className={styles["arrow"]}>→</span>
        </a> */}
      </div>
    </div>
  );
};

const QuickGlance = () => {
  const logos = [
    delhivery,
    bluedart,
    snowman,
    ecomexpress,
    dhl,
    mahindra,
    trackon,
    rivigo,
    ampl,
    loadshare,
    xpressbees,
    leap,
    apllogigistic,
    konoike,
    delhiprisons,
    delhipolice,
    adb,
    flipkart,
    hyperpure,
    zomato,
    reliance,
    myntra,
    bigbasket,
    adityabirla,
    amtek,
    eicher,
    dixon,
    siemens,
    sleepwell,
    samsung,
    lg,
    hnm,
    haier,
    vahdam,
    devans,
    morde,
    superplum,
    mundhra,
    maersk,
    cmacgm,
    hindterminal,
    hpcsl,
    storemygood,
    bharatpetroleum,
    pepfuels,
    organickashmir,
    willmar,
    icicibank,
    samsungsds,
    jio,
    cdac,
    brij,
    caratlane,
  ];
  const metricsData = [
    {
      title: "500",
      body: " Hijackings Saved",
    },
    {
      title: "4.5",
      body: "App Rating Stars ",
    },
    {
      title: "20%",
      body: "Increase In Vehicle Productivity",
    },
    {
      title: "80%",
      body: "Increase In SLA Adherence",
    },
  ];

  return (
    <div>
      {/* ------ Metrics ------ */}
      <div className={styles.metrics_container}>
        {metricsData.map((item, i) => {
          return (
            <div key={i} className={styles.metrics_box}>
              <p className={styles.metrics_title}>{item?.title}</p>
              <p className={styles.metrics_body}>{item?.body}</p>
            </div>
          );
        })}
      </div>

      {/* ------ Ticker section ------ */}
      <div className={styles.ticker_wrapper}>
        <div className={styles.ticker}>
          {[...logos, ...logos].map(
            (
              logo,
              index // Single duplication for seamless loop
            ) => (
              <div className={styles["ticker-item"]} key={index}>
                <img
                  src={logo}
                  alt={`logo-${index}`}
                  style={index % 2 === 0 ? { height: "75px" } : {}}
                />
              </div>
            )
          )}
        </div>
      </div>

      {/* ------ Main section ------ */}
      <GridContainer className={styles.main_outer_container}>
        <GridItem
          xs={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={styles.text_area_container}>
            <StyledHeading text="Lynktrac" />
            <StyledHeading
              text="- Quick Glance"
              noOrange={true}
              noStrike={true}
            />
            <p
              style={{ marginTop: "1rem", marginBottom: "2rem" }}
              className={styles.body_text}
            >
              Let's delve into the core elements that elevate our supply chain
              solution to redefine reliability and efficiently.
            </p>
            <button
              className={`${mainStyle.custom_button} ${mainStyle.filled_orange}`}
              onClick={() => {
                window.open("https://lynkit.in/ContactUs", "_blank");
              }}
            >
              Request A Demo
            </button>
          </div>
        </GridItem>
        <GridItem xs={12} md={6}>
          <div className={styles["tiles_container--outer"]}>
            <div className={styles["tiles-container--inner"]}>
              <Card
                icon={img1}
                title="Maximize asset utilization"
                description="Our real-time visibility feature empowers you with up-to-the-minute fleet tracking data, putting you in control and enhancing operational efficiency."
              />
              <Card
                icon={targetQuicker}
                title="Meet your ESG goals quicker"
                description="Our fleet tracking solution employs data-driven insights to cut expenses via route optimization and fuel management."
              />
            </div>
            <div
              className={`${styles["tiles-container--inner"]} ${styles["uneven-column"]}`}
            >
              <Card
                icon={antiTheft}
                title="Anti-Theft Solutions"
                description=" GPS locks, wireless-based door locks and cameras ensure a zero pilferage transit."
              />
              <Card
                icon={safety}
                title="Safety Is Our Top Priority"
                description="Implement video telematics for comprehensive driver behavior monitoring and cargo safety, providing an extra layer of security for your supply chain."
              />
            </div>
            {/* Add more Card components as needed */}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default QuickGlance;
