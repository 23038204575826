import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import dtop_bg from "../../assets/useCases/dtop_bg2.png";
import points from "../../assets/useCases/points.svg";
import heading_line from '../../assets/useCases/heading_line.svg';
import mobile_bg from '../../assets/useCases/mobile_bg.svg'
const UseCases = () => {
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [hoveredFixedPoint, setHoveredFixedPoint] = useState(null);

  // Store coordinates for different screen sizes
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define points as percentage-based values
  const pointsContent = {
    1: { title: "SKU Retrieval", text: "Accelerate SKU retrieval with advanced location mapping and digital instructions for precise picking, reducing search times." },
    2: { title: "Storage Utilisation", text: "Optimize storage layouts with heatmaps and utilization analytics to maximize space and improve inventory turnover rates." },
    3: { title: "Worker Productivity", text: "Assign tasks dynamically and monitor worker KPIs, ensuring efficient utilization of manpower with minimal idle time." },
    4: { title: "Loading", text: "Enable systematic loading with digital pick lists, load sequencing, and automated validation of outbound shipments for accurate dispatches." },
    5: { title: "Inventory Check", text: "Conduct accurate inventory audits using handheld scanners or RFID-based systems, ensuring real-time updates in the system." },
    6: { title: "Stacking", text: "Utilize AI-driven stacking recommendations for efficient use of warehouse space and quick accessibility of goods." },
    7: { title: "Dispatch Planning", text: "Automate dispatch planning with tools that allocate goods, sequence shipments, and provide ETA updates to reduce delays." },
    8: { title: "Unloading", text: "Automate unloading processes with barcode and RFID scanning to track inbound goods in real time, minimizing delays and errors." },
    9: { title: "Control Tower", text: "Delivers real-time warehouse visibility, tracks order and inventory flow, manages exceptions, and triggers alerts, empowering teams to proactively resolve issues and optimize performance across warehouse operations." },
    10: { title: "Inventory Monitoring", text: "Monitor inventory levels continuously with IoT sensors and real-time alerts to prevent overstocking or stockouts." },
  };

  // Coordinates for different screen sizes (as percentages)
  const getFixedPoints = () => {
    if (windowWidth >= 550) {
      return [
        { id: 1, x: 37, y: 25 },
        { id: 2, x: 56, y: 49 },
        { id: 3, x: 66, y: 60 },
        { id: 4, x: 79, y: 21 },
      ];
    }
     else if(windowWidth >= 430 && windowWidth <= 550){
      return [
        { id: 1, x: 24, y: 39 },
        { id: 2, x: 56, y: 46 },
        { id: 3, x: 66, y: 59 },
        { id: 4, x: 87, y: 34 },
      ];
    }
    else {
      return [
        { id: 1, x: 24, y: 39 },
        { id: 2, x: 56, y: 46 },
        { id: 3, x: 66, y: 58 },
        { id: 4, x: 87, y: 37 },
      ];
    }
  };

  const getMovablePoints = () => {
    if (windowWidth >= 550) {
      return [
        { id: 5, x: 27, y: 36 },
        { id: 6, x: 46, y: 55 },
        { id: 7, x: 74, y: 43 },
        { id: 8, x: 72, y: 13 },
        { id: 9, x: 58, y: 12 },
        { id: 10, x: 72, y: 36 },
      ];
    } 

     else if(windowWidth >= 430 && windowWidth <= 550){
      return [
        { id: 5, x: 8, y: 46 },
        { id: 6, x: 36, y: 57 },
        { id: 7, x: 80, y: 51 },
        { id: 8, x: 74, y: 28 },
        { id: 9, x: 55, y: 27 },
        { id: 10, x: 78, y: 42 },
      ];
    }
    else {
      return [
        { id: 5, x: 8, y: 46 },
        { id: 6, x: 36, y: 57 },
        { id: 7, x: 80, y: 51 },
        { id: 8, x: 74, y: 33 },
        { id: 9, x: 55, y: 33 },
        { id: 10, x: 78, y: 42 },
      ];
    }
  };

  const fixedPoints = getFixedPoints();
  const movablePoints = getMovablePoints();

  // Event handlers
  const handleMouseEnterFixed = (id) => {
    if (windowWidth >= 1000) {
      setHoveredFixedPoint(id);
    }
  };

  const handleMouseLeaveFixed = () => {
    if (windowWidth >= 1000) {
      setHoveredFixedPoint(null);
    }
  };

  const handleMouseEnterMovable = (id) => {
    if (windowWidth >= 1000) {
      setHoveredPoint(id);
    }
  };

  const handleMouseLeaveMovable = () => {
    if (windowWidth >= 1000) {
      setHoveredPoint(null);
    }
  };

  // Click functionality for smaller screens (under 1000px)
  const handleClickFixed = (id) => {
    if (windowWidth < 1000) {
      if(id<=4){
        setHoveredPoint(null);
        setHoveredFixedPoint(id);
      }
      else{
        setHoveredFixedPoint(null);
        setHoveredPoint(id);
      }
      
    }
  };

  const handleClickMovable = (id) => {
    if (windowWidth < 1000) {
      if(id<=4){
        setHoveredPoint(null);
        setHoveredFixedPoint(id);
      }
      else{
        setHoveredFixedPoint(null);
        setHoveredPoint(id);
      }
    }
  };

  const getContent = (id) => {
    return pointsContent[id] || {};
  };

  return (
    <div className={styles.usecases}>
      <div className={styles.heading}>
        Use <span>Cases</span>
        <img src={heading_line} alt="" className={styles.heading_img} />
      </div>

      <div className={styles.container}>
        <img src={dtop_bg} alt="desktopbg" className={styles.dtopImg} />
        <img src={mobile_bg} alt="mobilebg" className={styles.mobile_bg} />

        {/* Fixed Points */}
        {fixedPoints.map((point) => (
          <div
            key={point.id}
            className={styles.fixedPoint}
            style={{
              top: `${point.y}%`, // Position as percentage
              left: `${point.x}%`,
            }}
            onMouseEnter={() => handleMouseEnterFixed(point.id)}
            onMouseLeave={handleMouseLeaveFixed}
            onClick={() => handleClickFixed(point.id)} // Click for smaller screens
          >
            {/* Tooltip for fixed points */}
            {/* {hoveredFixedPoint === point.id && (
              <div className={styles.tooltip}>{pointsContent[point.id]?.title}</div>
            )} */}
            <img src={points} alt={`point-${point.id}`} className={styles.pointImage} />
          </div>
        ))}

        {/* Movable Points */}
        {movablePoints.map((point) => (
          <div
            key={point.id}
            className={styles.movablePoint}
            style={{
              top: `${point.y}%`,
              left: `${point.x}%`,
            }}
            onMouseEnter={() => handleMouseEnterMovable(point.id)}
            onMouseLeave={handleMouseLeaveMovable}
            onClick={() => handleClickMovable(point.id)} // Click for smaller screens
          >
            {/* Tooltip for movable points */}
            {/* {hoveredPoint === point.id && (
              <div className={styles.tooltip}>{pointsContent[point.id]?.title}</div>
            )} */}
            <img src={points} alt={`point-${point.id}`} className={styles.pointImage} />
          </div>
        ))}

        {/* Draw lines if hovering over a movable point */}
        {hoveredPoint &&
          fixedPoints.map((fixedPoint) => (
            <svg
              key={fixedPoint.id}
              className={styles.line}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            >
              <line
                x1={`${fixedPoint.x}%`}
                y1={`${fixedPoint.y}%`}
                x2={`${movablePoints.find((point) => point.id === hoveredPoint)?.x}%`}
                y2={`${movablePoints.find((point) => point.id === hoveredPoint)?.y}%`}
                stroke="#ff7700"
                strokeWidth="4"
              />
            </svg>
          ))}

        {/* Draw lines if hovering over a fixed point */}
        {hoveredFixedPoint &&
          fixedPoints.filter((fixedPoint) => fixedPoint.id !== hoveredFixedPoint).map((fixedPoint) => (
            <svg
              key={fixedPoint.id}
              className={styles.line}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            >
              <line
                x1={`${fixedPoint.x}%`}
                y1={`${fixedPoint.y}%`}
                x2={`${fixedPoints.find((point) => point.id === hoveredFixedPoint)?.x}%`}
                y2={`${fixedPoints.find((point) => point.id === hoveredFixedPoint)?.y}%`}
                stroke="#ff7700"
                strokeWidth="4"
              />
            </svg>
          ))}
      </div>

      {/* Display content when a point is hovered */}
      <div className={styles.content}>
        {(hoveredPoint || hoveredFixedPoint) ? (
          <div className={styles.contentBox}>
            <h3 className={styles.contentBoxHead}>
              {getContent(hoveredPoint || hoveredFixedPoint).title}
            </h3>
            <p className={styles.contentBoxPara}>
              {getContent(hoveredPoint || hoveredFixedPoint).text}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UseCases;


