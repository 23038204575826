import React from "react";
import styles from "./styles.module.css";
import dtopImage from "../../assets/heros_section/dtop_bg.png";
import mainStyles from "../../main.module.css";
import appStore from "../../assets/heros_section/app_icon.svg";
import playStore from "../../assets/heros_section/playstore_icon.svg";
import mobile_bg from "../../assets/heros_section/bg_mobile.png";
const Heros = () => {
	return (
		<>
			<div className={styles.hero_section}>
				<div className={styles.main_img_with_text_container}>
					<div className={styles.main_backdrop}>
						<img className={styles.dtopImage} src={dtopImage} />
					</div>
					
					<div className={styles.main_title_section}>
						<div className={styles.content}>
							{/* {`It's \ntime to make \n`} */}
							<span style={{color:'#C6C6C6'}} className={styles.firstLine}>{`A Warehouse Management System for all\n`}</span>
							<span style={{ color: "#FF7700" }} className={styles.secondLine}>{`D2C brands, E-commerce,\n Manufacturers, Distributors,\n Wholesalers, and 3PLs`}</span>
							<div className={styles.text_bg}>Say hello to Efficiency.</div>
						</div>
						<div className={styles.button_container}>
							<button
								className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
								style={{ width: "max-content" }}
								onClick={() => {
									window.open("https://lynkit.in/ContactUs", "_blank");
								}}
							>
								Book A Meeting
							</button>
							<div className={styles.app_btns}>
								{/* <div className={styles.app_btn}>
									<img
										src={appStore}
										onClick={() => {
											window.open("https://apps.apple.com/us/app/lynkit-io/id1501683225", "_blank");
										}}
									/>
								</div> */}
								<div className={styles.app_btn}>
									<img
										src={playStore}
										onClick={() => {
											window.open("https://play.google.com/store/apps/details?id=com.lynkit.lynkgrid_wms&hl=en", "_blank");
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>





			<div className={styles.heroContainer}>
				<img src={mobile_bg} className={styles.mobileImage} />
				<div className={styles.d_text_container}>
					<span className={styles.firstPara}>A Warehouse Management System for all-<br/></span>
					<span className={styles.secondPara} id={styles.secondParaMob}>D2C brands, E-commerce,Manufacturers,<br/> Distributors, Wholesalers, and 3PLs<br/></span>
					<span className={styles.secondPara} id={styles.secondParaTab}>D2C brands, E-commerce,Manufacturers, Distributors, Wholesalers, and 3PLs<br/></span>
					<span className={styles.thirdPara}>Say Hello To Efficiency.</span>
					<div className={styles.button_container}>
						<button
							className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
							style={{ width: "max-content" }}
							onClick={() => {
								window.open("https://lynkit.in/ContactUs", "_blank");
							}}
						>
							Book A Meeting
						</button>
						<div className={styles.app_btns}>
							<div className={styles.app_btn}>
								<img
									src={appStore}
									onClick={() => {
										window.open("", "_blank");
									}}
								/>
							</div>
							<div className={styles.app_btn}>
								<img
									src={playStore}
									onClick={() => {
										window.open("", "_blank");
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Heros;
