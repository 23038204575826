import React, { useState, useEffect } from 'react';
import styles from "./styles.module.css";
// import mainStyles from "../../main.module.css";
import { StyledHeading } from '../../HelperComponents';
import inventryAccuracyIcon from '../../assets/otherFeature/inventryAccuracyIcon.svg'
import materialReceiptImg from '../../assets/otherFeature/materialReceiptImg.svg'
import inventoryAccuracyImg from '../../assets/otherFeature/inventoryAccuracyImg.svg'
import inventoryProjection from '../../assets/otherFeature/inventoryProjection.svg'
import inventoryProjectionsIm from '../../assets/otherFeature/inventoryProjectionsIm.svg'
import internalMovementsImg from '../../assets/otherFeature/internalMovementsImg.svg'
import internalMovement from '../../assets/otherFeature/internalMovement.svg'
import easyOrder from '../../assets/otherFeature/easyOrder.svg'
import easyOrderProcessingIm from '../../assets/otherFeature/easyOrderProcessingIm.svg'
import scalability from '../../assets/otherFeature/scalability.svg'
import scalabilityimg from '../../assets/otherFeature/scalabilityimg.svg'
import integrate from '../../assets/otherFeature/integrate.svg'
import integrateIm from '../../assets/otherFeature/integrateIm.svg'
import reducedCosImg from '../../assets/otherFeature/reducedCostsImg.svg'
import Reduced_Costs_ from '../../assets/otherFeature/Reduced Costs_.svg'
import palletizationImg from '../../assets/otherFeature/palletizationImg.svg'
import Palletization from '../../assets/otherFeature/Palletization.png'
import reOrderingimg from '../../assets/otherFeature/reOrderingimg.svg'
import reordering from '../../assets/otherFeature/reordering.svg'
import view3D from '../../assets/otherFeature/view3D.png'
import view3Dimg from '../../assets/otherFeature/view3Dimg.png'
import kittingImg from '../../assets/otherFeature/kittingImg.svg'
import Kitting from '../../assets/otherFeature/Kitting.svg'
import esales from '../../assets/otherFeature/esales.svg'
import eSalesImg from '../../assets/otherFeature/eSalesImg.svg'
import mmd from '../../assets/otherFeature/Material Mapping to Dock.svg'
import mmdimg from '../../assets/otherFeature/materialMappingimg.svg'
import materialReceipt from '../../assets/otherFeature/materialReceipt.svg'
import { SlideRightComponent } from '../../AnimationConents';
const Tab = ({ info, active, onClick }) => {
  return (
    <div
      className={styles.feature_tab}
      style={{
        background: active ? "#FF7700" : "#FFFFFF",
        color: active ? "#FFFFFF" : "#595959", 
        
      }}
      onClick={onClick}
    >
      <img
        src={info?.icon}
        // style={{
        //   filter: active ? "invert(100%)" : "none" 
        // }}
      />
      <span className={styles.title}>
        {info?.title}
      </span>
    </div>
  );
};


const OtherFeature = () => {
const [current, setCurrent]=useState(0)
const[animate, setAnimate]=useState(false)
  const otherfeatures = [
    {
      title: "Material Receipts",
      icon: materialReceipt,
      img: materialReceiptImg,
      text: `Advance Shipping Notice \n\nLabeling and QC Steps \n\nDocking, Unloading, Receiving, and Put Away`   
    },

    {
      title: "Inventory Accuracy",
      icon: inventryAccuracyIcon,
      img: inventoryAccuracyImg,
      text: `Receiving and inspection \n\nOrder Fulfillment and Shipping \n\nSorting and stocking`
    },

    {
      title: "Inventory Projections",
      icon: inventoryProjection,
      img: inventoryProjectionsIm,
      text: `Notifications for stock levels and dates \n\nDispatch Management \n\nEnable lead times and get accurate re-order levels and notifications`
    },

    {
      title: "Optimizing Internal Movements",
      icon: internalMovement,
      img: internalMovementsImg,
      text: `Raw Material, Finished Goods Transfer, and QC checks \n\nArea Re-allocations, Location Checks, and Stock Audits `
    },

    {
      title: "Easy Order Processing",
      icon: easyOrder,
      img:easyOrderProcessingIm,
      text: `Picking, Packing, Invoicing and Dispatch \n\nSale order processing from initiation to fulfillment`
    },

    {
      title: "Scalability with Returnable Asset & Spare Part Management",
      icon: scalability,
      img: scalabilityimg,
      text: `Handles diverse use cases. \n\nSupports returnable asset management. \n\nTracks spare parts, adding versatility over other systems.`
    },

    {
      title: "Integrate with Advanced Route & Load Optimization",
      icon: integrate,
      img: integrateIm,
      text: `Integrates with routing tools. \n\nSupports 3D load planning. \n\nAutomates warehouse picking and stocking strategies. \n\nReduces inventory holding costs. \n\nIncreases fulfillment speed`
    }, 
    {
      title: "Reduced Costs",
      icon: Reduced_Costs_,
      img: reducedCosImg,
      text: `Streamline operations by reducing labor, inventory holding, and fulfillment costs through automation and optimization.`
    },
    {
      title: "Mapping to Docks",
      icon: mmd,
      img: mmdimg,
      text: `Easily manage and assign docks for incoming and outgoing shipments, optimizing the flow of goods and reducing delays during loading and unloading.`
    },
    {
      title: "Palletization",
      icon: Palletization,
      img: palletizationImg,
      text: `Optimize pallet configurations to utilize space better and move goods faster within the warehouse, improving operational efficiency.`
    },
    {
      title: "Re-ordering",
      icon: reordering,
      img: reOrderingimg,
      text: `Automate re-ordering processes with low stock alerts and predictive demand planning, ensuring timely inventory replenishment without manual intervention.`
    },
    {
      title: "3D View ",
      icon: view3D,
      img: view3Dimg,
      text: `Visualize your warehouse like never before! Our 3D Grid View provides a bird’s-eye perspective of your storage locations, streamlining inventory management.`
    },
    {
      title: "Kitting & Dekitting modules",
      icon: Kitting,
      img: kittingImg,
      text: `Now, you can streamline your operations with Kitting—bundle complementary items into a single package, cut down on picking and packaging costs, and boost sales of slower-moving items. Each kit comes with a unique SKU and a detailed Bill of Materials (BOM), making it easier to manage your inventory. \n\nWith De-Kitting, you can effortlessly break down kits and return individual components to inventory, whether due to returns or reassembly. This ensures seamless inventory tracking and full traceability from procurement to final sale or return.`
    },
    {
      title: "E-Sales Orders",
      icon: esales,
      img: eSalesImg,
      text: `Empower your customers with the new "Book Slot" feature, available for sales orders and e-Sales Order link creation. Ensure smooth scheduling and efficient order processing.`
    },
  
   
    
  ];

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 1000); // Match this duration with your CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [animate]);


  const handleTabClick=(i)=>{
    setCurrent(i);
    setAnimate(true)

  }

  return (
    <div className={styles.software_features_section}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledHeading text="Other Features" />
      </div>
      <div className={`${styles.features_outer_container} ${styles.large_screens}`}>
        <div className={styles.feature_tabs}>
          {otherfeatures?.map((feature, i) => {
            return (
              <Tab
                info={feature}
                active={i === current ? true : false}
                onClick={() => handleTabClick(i)}
                key={i}
              />
            );
          })}
        </div>

        <div className={styles.feature_content}>
          <div className={styles.text_content}>
            <span>{otherfeatures[current].title}</span>
            <p>{otherfeatures[current].text}</p>
          </div>
          <div className={styles.img_content}>
            {animate?
            <SlideRightComponent duration={1000}>
                <img src={otherfeatures[current].img} />
            </SlideRightComponent>
           :
           <img src={otherfeatures[current].img} />
           }
          </div>
        </div>

      </div>
      <div className={`${styles["slider-container"]} ${styles.small_screens}`}>
        <div className={`${styles["slider-wrapper"]}`}>
          {otherfeatures?.map((feature, i) => {
            return (
              <div className={`${styles["slider-item"]}`}>
                <div className={styles["title-row"]}>
                  <img src={feature.icon} />
                  <h3>{feature.title}</h3>
                </div>
                <hr style={{border: "1px solid #ff7700", opacity: 1}} />
                <p style={{fontSize: "14px"}}>{feature.text}</p>
                <img src={feature?.img} alt="Geofencing" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default OtherFeature













// import React, { useState, useEffect } from 'react';
// import styles from "./styles.module.css";
// import { StyledHeading } from '../../HelperComponents';
// import inventryAccuracyIcon from '../../assets/otherFeature/inventryAccuracyIcon.svg';
// import materialReceiptImg from '../../assets/otherFeature/materialReceiptImg.svg';
// import { SlideRightComponent } from '../../AnimationConents';

// const Tab = ({ info, active, onClick }) => {
//   return (
//     <div
//       className={styles.feature_tab}
//       style={{
//         background: active ? "#FF7700" : "#FFFFFF",
//         color: active ? "#FFFFFF" : "#595959",
//       }}
//       onClick={onClick}
//     >
//       <img
//         src={info?.icon}
//         style={{
//           filter: active ? "invert(100%)" : "none"
//         }}
//       />
//       <span className={styles.title}>
//         {info?.title}
//       </span>
//     </div>
//   );
// };

// const OtherFeature = () => {
//   const [current, setCurrent] = useState(0);
//   const [animate, setAnimate] = useState(false);

//   const otherfeatures = [
//     {
//       title: "Material Receipts",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Advance Shipping Notice \n\nLabeling and QC Steps \n\nDocking, Unloading, Receiving, and Put Away`
//     },

//     {
//       title: "Inventory Accuracy",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Receiving and inspection \n\nOrder Fulfillment and Shipping \n\nSorting and stocking`
//     },

//     {
//       title: "Inventory Projections",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Notifications for stock levels and dates \n\nDispatch Management \n\nEnable lead times and get accurate re-order levels and notifications`
//     },

//     {
//       title: "Optimizing Internal Movements",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Raw Material, Finished Goods Transfer, and QC checks \n\nArea Re-allocations, Location Checks, and Stock Audits`
//     },

//     {
//       title: "Easy Order Processing",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Picking, Packing, Invoicing and Dispatch \n\nSale order processing from initiation to fulfillment`
//     },

//     {
//       title: "Scalability with Returnable Asset & Spare Part Management",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Handles diverse use cases. \n\nSupports returnable asset management. \n\nTracks spare parts, adding versatility over other systems.`
//     },

//     {
//       title: "Kitting & Dekitting modules",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Now, you can streamline your operations with Kitting—bundle complementary items into a single package, cut down on picking and packaging costs, and boost sales of slower-moving items. Each kit comes with a unique SKU and a detailed Bill of Materials (BOM), making it easier to manage your inventory. \n\nWith De-Kitting, you can effortlessly break down kits and return individual components to inventory, whether due to returns or reassembly. This ensures seamless inventory tracking and full traceability from procurement to final sale or return.`
//     },
//     {
//       title: "E-Sales Orders",
//       icon: inventryAccuracyIcon,
//       img: materialReceiptImg,
//       text: `Empower your customers with the new "Book Slot" feature, available for sales orders and e-Sales Order link creation. Ensure smooth scheduling and efficient order processing.`
//     },
//   ];

//   useEffect(() => {
//     if (animate) {
//       const timer = setTimeout(() => {
//         setAnimate(false);
//       }, 1000); // Match this duration with your CSS transition duration

//       return () => clearTimeout(timer);
//     }
//   }, [animate]);

//   const handleTabClick = (i) => {
//     setCurrent(i);
//     setAnimate(true);
//   };

//   // Convert text with \n\n to bullet points (list items)
//   const renderTextWithBullets = (text) => {
//     const bulletText = text.split("\n\n").map((line, index) => (
//       <li key={index} style={{ fontSize: '14px', paddingBottom: '8px' }}>
//         {line}
//       </li>
//     ));
//     return <ul>{bulletText}</ul>;
//   };

//   return (
//     <div className={styles.software_features_section}>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <StyledHeading text="Other Features" />
//       </div>
//       <div className={`${styles.features_outer_container} ${styles.large_screens}`}>
//         <div className={styles.feature_tabs}>
//           {otherfeatures?.map((feature, i) => {
//             return (
//               <Tab
//                 info={feature}
//                 active={i === current ? true : false}
//                 onClick={() => handleTabClick(i)}
//                 key={i}
//               />
//             );
//           })}
//         </div>

//         <div className={styles.feature_content}>
//           <div className={styles.text_content}>
//             <span>{otherfeatures[current].title}</span>
//             {/* Render the text as a list */}
//             {renderTextWithBullets(otherfeatures[current].text)}
//           </div>
//           <div className={styles.img_content}>
//             {animate ? (
//               <SlideRightComponent duration={1000}>
//                 <img src={otherfeatures[current].img} />
//               </SlideRightComponent>
//             ) : (
//               <img src={otherfeatures[current].img} />
//             )}
//           </div>
//         </div>
//       </div>
//       <div className={`${styles["slider-container"]} ${styles.small_screens}`}>
//         <div className={`${styles["slider-wrapper"]}`}>
//           {otherfeatures?.map((feature, i) => {
//             return (
//               <div className={`${styles["slider-item"]}`} key={i}>
//                 <div className={styles["title-row"]}>
//                   <img src={feature.icon} />
//                   <h3>{feature.title}</h3>
//                 </div>
//                 <hr style={{ border: "1px solid #ff7700", opacity: 1 }} />
//                 <p style={{ fontSize: "14px" }}>{feature.text}</p>
//                 <img src={feature?.img} alt="Feature" />
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OtherFeature;
