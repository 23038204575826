import React from 'react';
import './styleFooter.css'
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import header_logo from '../../assets/footer/lynkgridLogo.svg'
import location from "../../assets/footer/location_1.png";
import call from "../../assets/footer/call_1.png";
import mail from '../../assets/footer/mail.png';
import fb from "../../assets/footer/fb.png";
import linkedin from "../../assets/footer/linkedin.png";
import twitter from "../../assets/footer/twitter.png";
import office from "../../assets/footer/oppice.png";






const Footer = () => {
    let date =new Date()
    let year=date.getFullYear()
   

    return (
        <div className="footer_main">
            <div className="footer_part1">
                <div className="footer_heading1">
                    <div>
                        <h1>Ready to Get Started?</h1><br />
                        <p>Share your contact details and our team will contact you shortly.</p>
                    </div>
                    
                </div>
                <button className='contact_btn' onClick={()=>{window.open('https://lynkit.in/ContactUs', '_blank')}}>
                        <a href='https://lynkit.in/ContactUs' target='_blank' ><span>Contact Us</span></a>
                </button>
                {/* <div className="email_1" >
                    <div>
                        <p style={{color:'black'}}>Click here to contact us</p>
                        <button  ><ArrowForwardIcon id="arrow2" /></button>
                    </div>
                </div> */}

            </div>

            <div className="footer_new_part">
                <div className="footer_logo">
                    <a href="#"> <img id="logo_footer" src={header_logo} alt="logo" /></a>
                </div>

                <div className="data_footer1">
                    <div className="data_footer_1">
                        <div className="location_all">
                            <a href="https://www.google.com/maps/dir//lynkit/@28.5254549,77.2054582,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x390ce1656fbb7b65:0x7f0e4b2c21935a5c!2m2!1d77.2754985!2d28.5254729" target="_blank"><img id="location" src={location} alt="none" /></a>
                            <img id="locationM1" src={office} alt="none" />
                            <img id="locationM" src={call} alt="none" />
                            <img id="locationM" src={mail} alt="none" />
                        </div>
                    </div>
                </div>

                <div className="addressdiv">
                    <div className='addressElem'>
                        <a id="addresss" href="https://www.google.com/maps/dir//lynkit/@28.5254549,77.2054582,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x390ce1656fbb7b65:0x7f0e4b2c21935a5c!2m2!1d77.2754985!2d28.5254729" target="_blank"> 246, Block A, Okhla Industrial Area Phase I, New Delhi-110020.</a><br />
                        <a id="addresss2" href="https://www.google.com/maps/dir//Aviationfly+-+Unit+208,+Level+1,+Gate+Avenue+-+South+Zone+Dubai+International+Financial+Center+507211+-+Dubai+-+United+Arab+Emirates/@25.2134854,55.273253,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3e5f430a51b4573b:0x3fdbda557be6195f!2m2!1d55.2775618!2d25.2080586!3e0" target="_blank"> Unit 208, Level 1 Gate Avenue - South Zone, Dubai International Financial Centre, Dubai, UAE.</a><br />
                    </div>
                    <a id="addresss_1" href="tel:+91 11 4082 4028">+91 11 4082 4028</a>
                    <a id="addresss_2" href="mailto:contact@lynkit.in">contact@lynkit.in</a>
                </div>


                <div className="data_footer3">
                    <p>Our Products</p>
                    <a className="data_footer3_1" href="https://www.lynktrac.io/" target="_blank"> Lynktrac®</a>
                    <a className="data_footer3_2" href="https://lynkit.io/" target="_blank"> Lynkit.io™</a>
                    <a className="data_footer3_3" href="https://www.lynkgrid.com/" target="_blank"> Lynkgrid®</a>
                    {/* <a className="data_footer3_3" href="https://wms.lynkgrid.com/" target="_blank"> Lynkgrid®- Warehouse</a> */}
                    <a className="data_footer3_4" href='https://www.lynkid.io/' target="_blank"> LynkID©</a>
                    <a className="data_footer3_4" href="https://www.pikmybox.com/" target="_blank"> PikMyBox™</a>
                </div>

                <div className="data_footer4">
                    <p>Company</p>
                    <a href='https://wms.lynkgrid.com/login' target='_blank' className='data_footer4_three'>Platform</a>
                    <a href='#features' className='data_footer4_three'>Software</a>
                    <a href='#usecases' className='data_footer4_three'>Use Cases</a>
                    <a href='https://www.lynkit.in/about_us' target='_blank' className='data_footer4_three'>About Us</a>
                    <a href='#api' className='data_footer4_three'>API</a>
                    <a href='https://lynkit.in/ContactUs' target='_blank' className='data_footer4_three'>Contact Us</a>
                </div>

                <div className="data_footer5">
                    <p>Connect With Us</p>
                    <div className="social">
                        <a target="_blank" href="https://www.facebook.com/LynkitIndia" > <img src={fb} alt="none" /></a>
                        <a target="_blank" href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQFDdEZYJhmMPQAAAXo4RtjIpsDBX8MVvY402c7ESFKSQBOXcx8nhR6JUAfwR36JcT--FjUC1wFllcg9cG-Bp8ilT8W_IUGLl5IE4fZf4NJmSrepair4QAM4n-APKKhTtBcvLIA=&originalReferer=https://www.lynkit.in/&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fcompany%2Flynkit-india"> <img src={linkedin} alt="none" /></a>
                        <a target="_blank" href="https://twitter.com/Lynkitindia"> <img src={twitter} alt="none" className='twitterLogo' /></a>
                    </div>
                </div>
            </div>
            <div className="last_foot">
                <p>Copyright ©  {year} Lynkit. All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer