// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import styles from "./styles.module.css";
// import heading_line from "../../assets/useCases/heading_line.svg";
// import img1 from '../../assets/iotDevices/img1.png'
// import img2 from '../../assets/iotDevices/img2.png'
// import img3 from '../../assets/iotDevices/img3.png'
// import Hexagon from "./Hexagon";

// const IotDevices = () => {
// 	// const [activeTab, setActiveTab] = useState(1);
//   const [groupValue, setGroupValue] = useState(0); // Manage groupValue state
//   const imgArray = [img1, img2, img3];

//   // Function to change the groupValue based on the hexagon click
//   const handleGroupValueChange = (value) => {
//     setGroupValue(value);
//   };
// 	const hexData = [
// 		{ id: 1, title: "Optimize", img: img1, color: "#ff7700" },
// 		{ id: 2, title: "Locate", img: img2, color: "#ff7700" },
// 		{ id: 3, title: "Enhance", img: img3, color: "#ff7700" },
// 		// { id: 4, title: "Automate", img: img4, color: "#6B6B6B" },
// 		// { id: 5, title: "Manage", img: img5, color: "#6B6B6B" },
// 		// { id: 6, title: "Focus", img: img6, color: "#6B6B6B" },
// 	];

// 	const handleTabClick = (id) => {
// 		setActiveTab(id);
// 	};

// 	return (
// 		<div className={styles.iotDevices}>
// 			<div className={styles.container}>
// 				<div className={styles.leftPart}>
// 					<div className={styles.heading}>
// 						<div className={styles.firstLine}>IoT Devices for</div>
// 						<div className={styles.secondLine}>
// 							Smart Warehousing
// 							<img src={heading_line} className={styles.heading_line} />
// 						</div>
// 					</div>

// 					<div className={styles.contentBox}>
// 						<div className={styles.firstPara}>
// 							BLE - Employing BLE (Bluetooth Low Energy) technology, our beacon system, integrated with stationary-mounted gateways, ensures seamless warehouse tracking of machinery and
// 							inventory. The accuracy of our system is directly correlated with the number of gateways, allowing us to achieve precise monitoring.
// 						</div>
// 						<br />
// 						<div className={styles.secondPara}>
// 							RFID forklift solution—This leverages RFID readers and antennas installed on forklifts, combined with RFID-tagged assets, to automatically capture pick-and-drop timings and
// 							locations within the warehouse. For cost-effective implementation, this solution can be further enhanced with GPS technology. In areas where WiFi installation may be
// 							challenging, our system is equipped with 4G gateways to ensure uninterrupted connectivity.
// 						</div>
// 					</div>
// 				</div>

// 				{/* Image with Zoom-In, Zoom-Out, and Opacity Animation */}
// 				{/* <motion.div
//           className={styles.hexImg}
//           key={activeTab}
//           initial={{ opacity: 0, scale: 0.8, rotate: -50 }}  // Start with opacity, scale, and rotation
//           animate={{ opacity: 1, scale: 1, rotate: 0 }}  // Fade in, zoom in, reset rotation
//           exit={{ opacity: 0, scale: 0.8, rotate: 50 }}  // Fade out, zoom out, rotate when exiting
//           transition={{
//             duration: 0.7,
//             ease: "easeOut",
//           }}
//         > */}
// 				{/* <img src={hexData[activeTab - 1].img} alt={hexData[activeTab - 1].title} /> */}
// 				{/* <MainHexagon />
//         </motion.div> */}
// 				<div className={styles.containerHexagon}>
// 					<div className={styles.wrapper}>
// 						{/* Pass the groupValue and handler to HexagonWithTriangles */}
// 						<Hexagon groupValue={groupValue + 1} onGroupValueChange={handleGroupValueChange} />
// 					</div>
// 					<div className={styles.imgBox}>
// 						{/* Display the image based on groupValue */}
// 						<img src={imgArray[groupValue]} alt={`Image ${groupValue + 1}`} className={styles.imgArray} />
// 					</div>
// 				</div>
// 			</div>

// 			{/* Tabs Section without animations on tabs */}
// 			{/* <div className={styles.tabs}>
// 				{hexData.map((item) => (
// 					<div
// 						key={item.id}
// 						className={`${styles.tab} ${activeTab === item.id ? styles.activeTab : ""}`}
// 						onClick={() => handleTabClick(item.id)}
// 						style={{
// 							backgroundColor: activeTab === item.id ? item.color : "transparent",
// 							border: `2px solid ${item.color}`,
// 						}}
// 						onMouseEnter={(e) => {
// 							// Change background color on hover dynamically
// 							e.target.style.backgroundColor = item.color;
// 						}}
// 						onMouseLeave={(e) => {
// 							// Reset background color after hover
// 							e.target.style.backgroundColor = activeTab === item.id ? item.color : "transparent";
// 						}}
// 					>
// 						{item.title}
// 					</div>
// 				))}
// 			</div> */}
// 		</div>
// 	);
// };

// export default IotDevices;

import React, { useState } from "react";
import styles from "./styles.module.css";
import heading_line from "../../assets/useCases/heading_line.svg";
import img1 from "../../assets/iotDevices/img1.svg";
import img2 from "../../assets/iotDevices/img2.png";
import img3 from "../../assets/iotDevices/img3.png";
import Hexagon from "./Hexagon";
import { motion } from "framer-motion";

const IotDevices = () => {
	const [groupValue, setGroupValue] = useState(0);
	const imgArray = [img1, img2, img3];

	const handleGroupValueChange = (value) => {
		setGroupValue(value);
		console.log(value)
	};

	return (
		<div className={styles.iotDevices}>
			<div className={styles.container}>
				<div className={styles.leftPart}>
					<div className={styles.heading}>
						<div className={styles.firstLine}>IoT Devices for</div>
						<div className={styles.secondLine}>
							Smart Warehousing
							<img src={heading_line} className={styles.heading_line} />
						</div>
					</div>


					{groupValue === 0 ? (
						<div className={styles.contentBox}>
						<div className={styles.firstPara}>
							<b>RFID Forklift - Automation</b><br/> <br/>RFID forklift uses RFID readers and antennas installed on forklifts, combined with RFID-tagged assets, to automatically capture pick-and-drop timings and
							locations within the warehouse. For cost-effective implementation, this solution can be further enhanced with GPS technology. In areas where WiFi installation may be
							challenging, our system is equipped with 4G gateways to ensure uninterrupted connectivity.
						</div>
					</div>
					) : groupValue === 1 ? (
						<div className={styles.contentBox}>
						<div className={styles.firstPara}>
						<b>BLE - Location</b><br/> <br/>Employing BLE (Bluetooth Low Energy) technology, our beacon system, integrated with stationary-mounted gateways, ensures seamless warehouse tracking of machinery and inventory. The accuracy of our system is directly correlated with the number of gateways, allowing us to achieve precise monitoring.
						</div>
					</div>
					) : groupValue === 2 ? (
						<div className={styles.contentBox}>
						<div className={styles.firstPara}>
						<b>RFID - Enhance</b><br/><br/>RFID enhances warehouse management by enabling automated, real-time inventory tracking through radio frequency signals. It eliminates manual barcode scanning, allowing bulk item identification without line-of-sight. RFID tags store SKU, batch, and location data, which is transmitted to WMS for instant updates, improving inventory accuracy, reducing shrinkage, and optimizing picking and replenishment workflows.
						</div>
					</div>
					) : (
						<p>Invalid value</p>
					)}


					{/* <div className={styles.contentBox}>
						<div className={styles.firstPara}>
							BLE - Employing BLE (Bluetooth Low Energy) technology, our beacon system, integrated with stationary-mounted gateways, ensures seamless warehouse tracking of machinery and
							inventory. The accuracy of our system is directly correlated with the number of gateways, allowing us to achieve precise monitoring.
						</div>
						<br />
						<div className={styles.secondPara}>
							RFID forklift solution—This leverages RFID readers and antennas installed on forklifts, combined with RFID-tagged assets, to automatically capture pick-and-drop timings and
							locations within the warehouse. For cost-effective implementation, this solution can be further enhanced with GPS technology. In areas where WiFi installation may be
							challenging, our system is equipped with 4G gateways to ensure uninterrupted connectivity.
						</div>
					</div> */}
				</div>
				<motion.div
					className={styles.containerHexagon}
					key={groupValue}
					initial={{ opacity: 0, scale: 1, rotate: -30 }} // Start with opacity, scale, and rotation
					animate={{ opacity: 1, scale: 1, rotate: 0 }} // Fade in, zoom in, reset rotation
					exit={{ opacity: 0, scale: 1, rotate: 30 }} // Fade out, zoom out, rotate when exiting
					transition={{
						duration: 0.7,
						ease: "easeOut",
					}}
				>
					<div className={styles.wrapper}>
						{/* Pass the groupValue and handler to HexagonWithTriangles */}
						<Hexagon groupValue={groupValue + 1} onGroupValueChange={handleGroupValueChange} />
					</div>
					<div className={styles.imgBox}>
						{/* Display the image based on groupValue */}
						<img src={imgArray[groupValue]} alt={`Image ${groupValue + 1}`} className={styles.imgArray} />
					</div>
				</motion.div>
				{/* <div className={styles.containerHexagon}>
					<div className={styles.wrapper}>
						
						<Hexagon groupValue={groupValue + 1} onGroupValueChange={handleGroupValueChange} />
					</div>
					<div className={styles.imgBox}>
						
						<img src={imgArray[groupValue]} alt={`Image ${groupValue + 1}`} className={styles.imgArray} />
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default IotDevices;
