import React, { useState } from "react";
import styles from "./styles.module.css";
import { StyledHeading } from "../../HelperComponents";
import { SlideUpComponent } from "../../AnimationConents";
import barcodePrinter from '../../assets/hardware/barcodePrinter.svg'
import barcodeScanner from '../../assets/hardware/barcodeScanner.svg'
import ble from '../../assets/hardware/ble.svg'
import rfidTag from '../../assets/hardware/rfidTag.svg'
import temperatureSensors from '../../assets/hardware/temperatureSensors.svg'
import uhf from '../../assets/hardware/uhf.svg'
import labelling from '../../assets/hardware/labelling.svg'
import dimentioner from '../../assets/hardware/dimentioner.png'
const products = [
  {
    id: 1,
    title: "RFID Tags",
    image: rfidTag,
    details:
      "Passive tags used for multiple asset type as Metallic, plastic, etc. Can either be hung, paste or screwed on the assets.",
  },
  {
    id: 2,
    title: "BLE Gateways",
    image: ble,
    details:
      "BLE beacons can accurately track a person's location in indoor spaces, such as shopping malls and office building.",
  },
  {
    id: 3,
    title: " Barcode Printer",
    image: barcodePrinter,
    details: "Printing barcode labels or tags that can be attached to, or printed directly on, physical objects",
  },
  {
    id: 4,
    title: "Labelling Machine",
    image: labelling,
    details: "Automates label application for efficient tracking, sorting, and inventory management.",
  },
  {
    id: 5,
    title: " Barcode or QR Code Handheld Scanner",
    image: barcodeScanner,
    details:
      "For reading tags indoors and outdoors in any variable location where fixed readers and antennas are not possible.",
  },
  {
    id: 6,
    title: "Temperature Sensors",
    image: temperatureSensors,
    details:
      "Reefer or a truck, we have you covered! Our wired and wireless sensors offer zone-wise temperature detection.",
  },
  {
    id: 7,
    title: "UHF",
    image: uhf,
    details: "Automatic picking, automatic sorting, and real-time tracking of goods location in plant/warehouses.",
  },
  {
    id: 8,
    title: "Dimentioner",
    image: dimentioner,
    details: "Automatically measures parcel size and weight for accurate storage, shipping, and cost calculation.",
  },
  
];

const gradients = [
  styles.gradientTopLeft,
  styles.gradientBottomLeft,
  styles.gradientBottomRight,
  styles.gradientTopRight
];

const Hardware = () => {
  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleProductClick = (id) => {
    setSelectedProductId(selectedProductId === id ? null : id);
  };

  const getRandomGradient = () => {
    return gradients[Math.floor(Math.random() * gradients.length)];
  };

  return (
    <div className={styles.hardware_container_outer}>
      <div>
        <StyledHeading text="Hardware Products" />
        <SlideUpComponent duration={1500}>
          <p className={styles.heading_para}>Explore The Awesome</p>
        </SlideUpComponent>
        
      </div>
      <div className={styles.hardware_products_container}>
        <div className={styles.product_grid}>
          {products.map((product) => (
            <div
              key={product.id}
              className={`${styles.product_box} ${selectedProductId === product.id ? styles.selected : ""}`}
              onClick={() => handleProductClick(product.id)}
            >
              <div
                className={`${styles.product_img_div} ${getRandomGradient()}`}
              >
                <img
                  src={product.image}
                  alt={product.title}
                  className={styles.product_image}
                />
              </div>

              <div
                className={`${styles.right_part} ${getRandomGradient()}`}
              >
                <h3 className={styles.product_title}>{product.title}</h3>

                {selectedProductId === product.id && (
                  <p className={styles.product_details}>{product.details}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hardware;
