import React from "react";
import { GridContainer, GridItem, StyledHeading } from "../../HelperComponents";
import mainStyle from "../../main.module.css";
import styles from "./styles.module.css";
import Increased_Efficiency from '../../assets/quickglance/Increased_Efficiency.svg'
import Intelligent from '../../assets/quickglance/Intelligent.svg'
import Optimized from '../../assets/quickglance/Optimized.svg'
import Real_time_Insight from '../../assets/quickglance/Real-time_Insight.svg'
import Real_time from '../../assets/quickglance/Real_time.svg'
import Seamless from '../../assets/quickglance/Seamless.svg'
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import back_btn_active from "../../assets/quickglance/back_btn_active.svg";
import next_active_btn from "../../assets/quickglance/next_btn_active.svg"
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);
const Card = ({ icon, title, description, className }) => {
    return (
      <div className={`${styles["card"]} ${className}`}>
        <div className={styles["card-icon"]}>
          <img src={icon} alt="icon" />
        </div>
        <div className={styles["card-content"]}>
          <h3 className={styles["card-title"]}>{title}</h3>
          <p className={styles["card-description"]}>{description}</p>
          {/* <a href="#" className={styles["card-link"]}>
            Read More <span className={styles["arrow"]}>→</span>
          </a> */}
        </div>
      </div>
    );
  };
const QuickGlance = () => {
	const card = [
		{
			icon:  Real_time ,
			title: "Real-time Inventory Tracking",
			description:
				"With real-time updates, you can accurately track inventory, prevent stock-outs or overstocking, and ensure smooth warehouse operations.",
		},
		{
			icon:  Increased_Efficiency ,
			title: "Increased Efficiency and Productivity",
			description:
				"Boost productivity by accelerating warehouse operations, optimizing the use of assets and resources, and reducing manual labor.",
		},
		{
			icon:  Real_time_Insight,
			title: "Real-time Insights",
			description:
				"With data-driven insights, you can gain complete visibility of your warehouse operations, enabling smarter decision-making and operational improvements.",
		},
		{
			icon:  Intelligent ,
			title: "Intelligent Storage and Putaway Strategies",
			description:
				"Implement advanced putaway strategies, including ABC analysis, to organize inventory based on demand and value. This reduces time spent locating products and ensures efficient use of storage space.",
		},
		{
			icon:  Optimized ,
			title: "Optimized Pick Lists and Routing",
			description:
				"Generate optimized pick lists with internal warehouse routing for workers, minimizing travel time and improving order fulfillment speed.",
		},
		{
			icon:  Seamless ,
			title: "Seamless Fulfillment",
			description:
				"Swiftly pick, pack, and deliver orders with precision to boost customer satisfaction and streamline the fulfillment process.",
		},
	];
	return (
		<div style={{marginTop:'3rem'}}>
			<GridContainer className={styles.main_outer_container}>
				<GridItem xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<div className={styles.text_area_container}>
						<StyledHeading text="Lynkgrid WMS" />
						<StyledHeading text="- A Quick Glance" noOrange={true} noStrike={true} />
						<p style={{ marginTop: "1rem", marginBottom: "2rem" }} className={styles.body_text}>
						Lynkgrid WMS is a robust Warehouse Management System crafted to optimize inventory, streamline warehouse operations, and enhance productivity for businesses across industries.

						</p>
						<button
							className={`${mainStyle.custom_button} ${mainStyle.filled_orange}`}
							onClick={() => {
								window.open("https://lynkit.in/ContactUs", "_blank");
							}}
						>
							Request A Demo
						</button>
					</div>
				</GridItem>
				<GridItem xs={12} md={6} className={styles.GridContainerBox}>
					<div className={styles["tiles_container--outer"]}>
						<div className={styles["tiles-container--inner"]}>
							<Card
								icon={Real_time} 
								title="Real-time Inventory Tracking" 
								description="With real-time updates, you can accurately track inventory, prevent stock-outs or overstocking, and ensure smooth warehouse operations." 
							/>
							<Card 
								icon={Increased_Efficiency} 
								title="Increased Efficiency and Productivity" 
								description="Boost productivity by accelerating warehouse operations, optimizing the use of assets and resources, and reducing manual labor." 
							/>
                            <Card 
								icon={Real_time_Insight} 
								title="Real-time Insights" 
								description="With data-driven insights, you can gain complete visibility of your warehouse operations, enabling smarter decision-making and operational improvements." 
							/>
							
						</div>


						<div className={`${styles["tiles-container--inner"]} ${styles["uneven-column"]}`}>
							<Card
								icon={Intelligent}
								title="Intelligent Storage and Putaway Strategies"
								description="Implement advanced putaway strategies, including ABC analysis, to organize inventory based on demand and value. This reduces time spent locating products and ensures efficient use of storage space."
							/>
							<Card
								icon={Optimized}
								title="Optimized Pick Lists and Routing"
								description="Generate optimized pick lists with internal warehouse routing for workers, minimizing travel time and improving order fulfillment speed."
							/>
							<Card
								icon={Seamless}
								title="Seamless Fulfillment"
								description="Swiftly pick, pack, and deliver orders with precision to boost customer satisfaction and streamline the fulfillment process."
							/>
						</div>
					</div>
				</GridItem>
			</GridContainer>
			<div className={styles.main_outer_container_mobile}>
				<Swiper
					slidesPerView={3} // Default: 3 slides visible
					loop={true}
					freeMode={true} // Enable FreeMode
					centeredSlides={false} // Ensure slides start at the left edge
					scrollbar={true}
					navigation={{
						prevEl: `.${styles.customSlider_prevButton}`,
						nextEl: `.${styles.customSlider_nextButton}`,
					}}
					
					breakpoints={{
						
						900: {
							slidesPerView: 3, // Slightly show the next slide
							spaceBetween: 15,
						},
						615: {
							slidesPerView: 2, // Tablets: 2 slides
							spaceBetween: 20,
						},
						250: {
							slidesPerView: 1, // Mobile: 1 slide
							spaceBetween: 20,
						},
					}}
					className={styles.customSlider_swiper}
				>
					{/* Slides */}
					{card?.map((slide, i) => {
						return (
							<SwiperSlide key={i} className={styles.customSlider_slide}>
								<div className={`${styles["card"]} `}>
									<div className={styles["card-icon"]}>
										<img src={slide.icon} alt="icon" />
									</div>
									<div className={styles["card-content"]}>
										<h3 className={styles["card-title"]}>{slide.title}</h3>
										<p className={styles["card-description"]}>{slide.description}</p>
									</div>
								</div>
							</SwiperSlide>
							
						);
					})}
				</Swiper>
				<div className={styles.button_Container}>
				<button className={`${styles.customSlider_navButton} ${styles.customSlider_prevButton}`}>
				<img src={ back_btn_active} className={`${styles.customSlider_navButton} ${styles.customSlider_prevButton}`} />
				</button>
				<button className={`${styles.customSlider_navButton} ${styles.customSlider_nextButton}`}>
				<img src={next_active_btn} className={`${styles.customSlider_navButton} ${styles.customSlider_nextButton}`} /> 
				</button>
				</div>
			</div>
		</div>
	);
};

export default QuickGlance;
