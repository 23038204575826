import React from "react";
import { GridContainer, GridItem, StyledHeading } from "../../HelperComponents";
import mainStyle from "../../main.module.css";
import styles from "./styles.module.css";
import creating from "../../assets/quickGlance/creating.svg";
import driver3 from "../../assets/quickGlance/driver3.svg";
import routeand5 from "../../assets/quickGlance/routeand5.svg";
import routeTracking7 from "../../assets/quickGlance/routeTracking7.svg";
import inplant9 from "../../assets/quickGlance/inplant9.svg";
import eproof11 from "../../assets/quickGlance/eproof11.svg";
import freight2 from "../../assets/quickGlance/freight2.svg";
import fleet4 from "../../assets/quickGlance/fleet4.svg";
import load6 from "../../assets/quickGlance/load6.svg";
import indenting8 from "../../assets/quickGlance/indenting8.svg";
import document10 from "../../assets/quickGlance/document10.svg";
import supply12 from "../../assets/quickGlance/supply12.svg";
import billing from "../../assets/quickGlance/billing.svg";
import back_btn_active from "../../assets/back_btn_active.svg";
import next_active_btn from "../../assets/next_btn_active.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import planningIndenting from "../../assets/quickGlance/planningIndenting.svg";
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper/core";
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);
const Card = ({ icon, title, description, className }) => {
	return (
		<div className={`${styles["card"]} ${className}`}>
			<div className={styles["card-icon"]}>
				<img src={icon} alt="icon" />
			</div>
			<div className={styles["card-content"]}>
				<h3 className={styles["card-title"]}>{title}</h3>
				<p className={styles["card-description"]}>{description}</p>
				{/* <a href="#" className={styles["card-link"]}>
            Read More <span className={styles["arrow"]}>→</span>
          </a> */}
			</div>
		</div>
	);
};
const QuickGlance = () => {
	const card = [
		{
			icon:  creating ,
			title: "Creating Your Digital Network",
			description:
				"Building your own network of shippers, transporters, suppliers, and customers allows for seamless data sharing across your supply chain management (SCM). This network not only enhances collaboration but also streamlines your overall logistics and transportation processes.",
		},
		{
			icon:  billing ,
			title: "Billing, Provisioning, and Expense Management",
			description:
				"A digital trail of each delivery ensures full visibility into metrics that might have been previously overlooked. With our TMS software, you can monitor vendor performance and track freight contract compliance. The system provides real-time alerts for any violations, helping you make data-driven decisions on vendor selection, shipment types, and rate negotiations. With these tools, your freight management becomes more efficient. Our internal market analysis shows Lynkit.io can save up to 4 hours per person (40% of a working day)",
		},
		{
			icon:  planningIndenting ,
			title: "Indenting, Planning, and Scheduling",
			description:
				"Set schedules and rules for your plant to streamline vehicle positioning using our smart appointment system. The Transport Management System (TMS) ensures that only a defined number of trucks are inside the plant at a time, preventing traffic and congestion, thereby optimizing logistics and transportation operations.",
		},
		{
			icon:  routeand5 ,
			title: "Trip Creation and Tracking",
			description:
				"Using GPS, wireless-based door locks, and cameras, our TMS software ensures zero pilferage during transit. This guarantees secure freight movement and reinforces the efficiency of your supply chain management.",
		},
	];
	return (
		<div style={{marginTop:'3rem'}}>	
			<GridContainer className={styles.main_outer_container}>
				<GridItem xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<div className={styles.text_area_container}>
						<StyledHeading text="Lynkit.io" />
						<StyledHeading text="- Quick Glance" noOrange={true} noStrike={true} />
						<p style={{ marginTop: "1rem", marginBottom: "2rem" }} className={styles.body_text}>
							Lynkit.io is a comprehensive Transport Management System and Supply Chain Management Solution designed to streamline operations for all participants in the supply chain
							industry.
						</p>
						<button
							className={`${mainStyle.custom_button} ${mainStyle.filled_orange}`}
							onClick={() => {
								window.open("https://lynkit.in/ContactUs", "_blank");
							}}
						>
							Request A Demo
						</button>
					</div>
				</GridItem>
				<GridItem xs={12} md={6}  className={styles.GridContainerBox}>
					<div className={styles["tiles_container--outer"]}>
						<div className={styles["tiles-container--inner"]}>
							<Card
								icon={creating}
								title="Creating Your Digital Network"
								description="Building your own network of shippers, transporters, suppliers, and customers allows for seamless data sharing across your supply chain management (SCM). This network not only enhances collaboration but also streamlines your overall logistics and transportation processes."
							/>
							<Card
								icon={billing}
								title="Billing, Provisioning, and Expense Management"
								description="A digital trail of each delivery ensures full visibility into metrics that might have been previously overlooked. With our TMS software, you can monitor vendor performance and track freight contract compliance. The system provides real-time alerts for any violations, helping you make data-driven decisions on vendor selection, shipment types, and rate negotiations. With these tools, your freight management becomes more efficient. Our internal market analysis shows Lynkit.io can save up to 4 hours per person (40% of a working day)"
							/>
							{/* <Card
								icon={driver3}
								title="Driver Management"
								description="The driver management will help to create a digital ledger of all drivers for recording their KYC details as well as maintaining all records of their expenses, attendance, traffic violations, customer complaints. Drivers can be given access and mapped to each trip for backtracking and salary payment automation."
							/>
							<Card
								icon={routeand5}
								title="Route and Trip Creation and Budgeting"
								description="GPS and wireless-based door locks and cameras ensure a zero pilferage transit."
							/>
							<Card
								icon={routeTracking7}
								title="Route Tracking and Planning"
								description="For delivery, the onbaorded drivers can be easily directed, to ensure timely delivery management with LynkNav - Lynkit’s last mile management product. Once the drivers are allocated by the transporters, they will be able to see the routes assigned, the customer address, contact number, ETA, traffic, delivery instructions and best possible route to take"
							/>
							<Card
								icon={inplant9}
								title="In-plant Logistics"
								description="Lynkit’s IoT layers allow you to create your plant into a digital fortress. The hardware integrates with the TMS to allow access to only verified vehicles into the plant. Integrations with weighbridges allow for cross checking and autopopulation of freight amounts and loads to avoid post departure surprises. The system further allows for a full parking management system giving you insights into all your loading and unloading times and routing vehicles to the correct slots."
							/>
							<Card
								icon={eproof11}
								title="E-proof of Deliveries"
								description="Say goodbye to lost PODs. Validations such as OTP authentication for delivery confirmation and tracking make our electronic PODs a secure and convenient system. The app also enables the consignees or warehouses to indicate any delivery exceptions, such as damaged goods or short shipment and upload photos - location during submission is also captured."
							/> */}
						</div>

						<div className={`${styles["tiles-container--inner"]} ${styles["uneven-column"]}`}>
							<Card
								icon={planningIndenting}
								title="Indenting, Planning, and Scheduling"
								description="Set schedules and rules for your plant to streamline vehicle positioning using our smart appointment system. The Transport Management System (TMS) ensures that only a defined number of trucks are inside the plant at a time, preventing traffic and congestion, thereby optimizing logistics and transportation operations."
							/>
							<Card
								icon={routeand5}
								title="Trip Creation and Tracking"
								description="Using GPS, wireless-based door locks, and cameras, our TMS software ensures zero pilferage during transit. This guarantees secure freight movement and reinforces the efficiency of your supply chain management."
							/>
							{/* <Card
								icon={fleet4}
								title="Fleet Management"
								description="Fleet management is designed to control costs, manage productivity and reduce risks. This module is linked with the contracts as well to ensure the billing amounts and revenue/expense per vehicle is maintained. Service records and expenses of the fleets can be recorded along with the ability to manage multiple documents like RC, permit, Insurance, pollution, etc and get alerts notification of expiry."
							/>
							<Card
								icon={load6}
								title="Load and Vehicle Planning Using AI"
								description="This module is based on artificial intelligence and predictive algorithms to create the ideal loading plan. Orders and production plans can be integrated from your ERP and showing a cost comparison between various fleets/transporters based on created contracts."
							/>
							
							<Card
								icon={document10}
								title="Document Creation for Trips"
								description="All corresponding transport documentation of these DOs - such as invoices, lorry receipts, e-way bills, consolidation challans can be generated through Lynkit.io. Logistics teams can send service requests to the transporters via this platform with all shipment details. Transporter can directly access your shipment information through their own accounts and generate the required documents."
							/>
							<Card
								icon={supply12}
								title="Supply Chain Control Towers"
								description="Our 360 degree CTs combine our tracking dashboard with status regarding current location, hub waiting time, ETA/ETD, excess delay, early arrival, excess stoppage with Performance monitoring metrics to see vendor and transporter wise delays, route-wise performance, transporter billing and P/L summaries, total penalties imposed, trip and shipment exception highlighting, and KYC analysis to make you truly into a “know it all”."
							/> */}
						</div>
						{/* Add more Card components as needed */}
					</div>
				</GridItem>
			</GridContainer>
			<div className={styles.main_outer_container_mobile}>
				<Swiper
					slidesPerView={2} // Default: 3 slides visible
					loop={true}
					freeMode={true} // Enable FreeMode
					centeredSlides={false} // Ensure slides start at the left edge
					scrollbar={true}
					navigation={{
						prevEl: `.${styles.customSlider_prevButton}`,
						nextEl: `.${styles.customSlider_nextButton}`,
					}}
					
					breakpoints={{
						
						900: {
							slidesPerView: 3, // Slightly show the next slide
							spaceBetween: 15,
						},
						625: {
							slidesPerView: 2, // Tablets: 2 slides
							spaceBetween: 10,
						},
						250: {
							slidesPerView: 1, // Mobile: 1 slide
							spaceBetween: 10,
						},
					}}
					className={styles.customSlider_swiper}
				>
					{/* Slides */}
					{card?.map((slide, i) => {
						return (
							<SwiperSlide key={i} className={styles.customSlider_slide}>
								<div className={`${styles["card"]} `}>
									<div className={styles["card-icon"]}>
										<img src={slide.icon} alt="icon" />
									</div>
									<div className={styles["card-content"]}>
										<h3 className={styles["card-title"]}>{slide.title}</h3>
										<p className={styles["card-description"]}>{slide.description}</p>
									</div>
								</div>
							</SwiperSlide>
							
						);
					})}
				</Swiper>
				<div className={styles.button_Container}>
				<button className={`${styles.customSlider_navButton} ${styles.customSlider_prevButton}`}>
				<img src={ back_btn_active} className={`${styles.customSlider_navButton} ${styles.customSlider_prevButton}`} />
				</button>
				<button className={`${styles.customSlider_navButton} ${styles.customSlider_nextButton}`}>
				<img src={next_active_btn} className={`${styles.customSlider_navButton} ${styles.customSlider_nextButton}`} /> 
				</button>
				</div>
			</div>
		</div>
	);
};

export default QuickGlance;
