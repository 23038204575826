import React from "react";
import mainStyles from "../../main.module.css";
import styles from "./styles.module.css";
import { StyledHeading } from "../../HelperComponents";
import logistics3pls from "../../assets/industries/logistics3pl.svg";
import warehouse_dist from "../../assets/industries/warehousing.svg";
import automobiles from "../../assets/industries/automobiles.svg";
import export_import from "../../assets/industries/export_import.svg";
import consumer_elec from "../../assets/industries/consumer_elec.svg";
import public_sec_govt from "../../assets/industries/public_sec_govt.svg";
import ecom from "../../assets/industries/ecom.svg";
import container_terminals from "../../assets/industries/container_terminals.svg";
import mining_metals from "../../assets/industries/mining_metals.svg";
import railways from "../../assets/industries/railways.svg";
import shipping_lines from "../../assets/industries/shipping_lines.svg";
import public_transport from "../../assets/industries/public_transport.svg";
import d2c from "../../assets/industries/d2c.svg";
import security from "../../assets/industries/security.svg";
import oil_gas_energy from "../../assets/industries/oil_gas_energy.svg";
import air_cargo from "../../assets/industries/air_cargo.svg";
import it from "../../assets/industries/it.svg";
import bonded_warehouse from '../../assets/industries/bonded_warehouse.svg'
import marketplace from '../../assets/industries/marketplace.svg'
import pharma from '../../assets/industries/pharma.svg'
import jewellery from '../../assets/industries/jewellery.svg'
import { FadeInComponent, SlideLeftComponent, SlideRightComponent } from "../../AnimationConents";

const Industries = ({ current, setCurrent }) => {
	const industries = [
		{
			name: "Logistics and 3PLs",
			img: logistics3pls,
			content: [
				"Quicker Distribution",
				"Space Utilisation",
				"Automated Inbound & Outbound Processing",
				"Seamless Multi-Client Management",

			],
		},
		{
			name: "Warehousing and Distribution",
			img: warehouse_dist,
			content: ["Space Utilisation","Digital Twin Visualization","Automated Job Allocation Based On Manpower Tracking", "Faster Order Processing"],
		},
		{
			name: "Public Sector and Government",
			img: public_sec_govt,
			content: ["File Storage","Disaster Relief & Emergency Stock Handling","Regulatory Compliance & Reporting","Optimized Supply Chain for Public Services"],
		},
		{
			name: "Marketplace",
			img: marketplace,
			content: ["Sales Order Processing","Real-Time Inventory Sync","Returns Management","Efficient Order Fulfillment"],
		},
		{
			name: "D2C",
			img: d2c,
			content: ["Omni Channel Integrations","Expiry Management For Perishables","Shipping Labels","Demand Forecasting & Stock Replenishment"],
		},
		{
			name: "Pharma and Healthcare",
			img: pharma,
			content: ["Fefo", "Cold Chain Compliance", "Regulatory Compliance & Audit Readiness", "Batch & Expiry Date Tracking" ],
		},
		{
			name: "Automobiles",
			img: automobiles,
			content: ["Spare Part Tracking And Management", "Production To Inventory Tracking And Management Of Packaging And Storage Cycles", "Kitting & Assembly Line Support", "Return & Warranty Management"],
		},
		{
			name: "E-commerce",
			img: ecom,
			content: ["Inventory Tracking And Management Of Packaging And Storage Cycles","Receiving And Dispatch Planning","Job Management For Users", "Smart Storage & Space Utilization"],
		},
		{
			name: "Railways",
			img: railways,
			content: ["Spare Part Tracking And Management", "Just-in-Time (JIT) Inventory for Maintenance Depots","Regulatory Compliance & Safety Standard", "Tracking & Distribution of Railway Consumables"],
		},
		{
			name: "Security & Surveillance",
			img: security,
			content: ["Tracking And Management Of Stored Cash, Documents And Sensitive Items", "Automated Cash Handling & Reconciliation","Secure Access Control & Authentication"],
		},
		{
			name: "Gems and Jewellery",
			img: jewellery,
			content: ["Inventory Management And Tracking","Secure Storage & Access Control","Automated Order Processing & Fulfillment","Return & Repair Tracking"],
		},
		{
			name: "Export and Import",
			img: export_import,
			content: ["International e-PODs", "IEC integrations", "Automated Duty & Tax Calculations", "Reverse Logistics & Returns Management"],
		},
		{
			name: "Container Terminals",
			img: container_terminals,
			content: ["Linkage With Container Stuffing And Destuffing From Lynkgrid Yms", "Automated Container Yard Management", "Seamless Integration with Port & Shipping Systems","Optimized Crane & Equipment Utilization"],
		},
		{
			name: "Oil, Gas and Energy",
			img: oil_gas_energy,
			content: ["Inventory Tracking And Storage Of Cylinders, Lubricants", "Factory Spare Parts For Internal Consumption","Hazardous Material Storage & Compliance", "Predictive Maintenance Scheduling"],
		},
		{
			name: "IT",
			img: it,
			content: ["SDKs for integrations with ERPs","Secure Storage & Access Control","Real-Time IT Asset Tracking","Reverse Logistics for IT Repairs & Upgrades"],
		},
		{
			name: "Consumer Electronics",
			img: consumer_elec,
			content: ["Inventory and SKU management", "Kitting modules for manufacturing efficiency", "Production tracking to packaging", "Batch & Serial Number Tracking"],
		},
		{
			name: "Mining and Metals",
			img: mining_metals,
			content: ["Inventory Management Of Coils, Ingots, Pipes, Bricks Etc", "Heavy Equipment & Spare Parts Management","Tracking & Traceability of Metal Batches"],
		},
		{
			name: "Public Transport Networks",
			img: public_transport,
			content: ["Automated billing and invoicing", "Automated Replenishment for Maintenance Depots", "Optimized Fleet Equipment Distribution","Real-Time Spare Parts Inventory Management"],
		},
		{
			name: "Retail",
			img: air_cargo,
			content: ["Inventory Management At Stores", "Sales Order Processing", "Omnichannel Order Fulfillment", "Returns & Reverse Logistics Management"],
		},
		{
			name: "Air Cargo",
			img: air_cargo,
			content: ["Inventory Management For Planes And Equipment", "Inventory And Dispatch Management For Cargo Handling Hubs Inside", "Integration with Customs & Compliance Systems", "Automated Labeling & RFID Tracking"],
		},
		{
			name: "Bonded Warehouses",
			img: bonded_warehouse,
			content: ["Storage Of Liquor Bottles And Cases", "Digitisation Of Bonded Register","Real-Time Duty-Free Inventory Tracking","Automated Customs Compliance & Documentation"],
		},
	];
	return (
		<div className={styles.outer_container}>
			<StyledHeading text="Industries" />
			<div className={styles.slides_area}>
				<div className={styles.tab_container_outer}>
					{industries.map((industry, i) => {
						return (
							<div
								className={styles.tab}
								style={{
									background: current === i ? "#ff7700" : "#ffffff",
									color: current === i ? "#ffffff" : "#747576",
								}}
								onClick={() => setCurrent(i)}
							>
								{industry?.name}
							</div>
						);
					})}
				</div>
				{industries?.map((industry, i) => {
					if (i === current) {
						return (
							<div className={styles.info_section}>
								<div className={styles.details}>
									{/* <div className={styles.details_title}>
										<span>{industry?.name}</span>
									</div> */}
									<div className={styles.details_content} style={{display:'flex'}}>
										{window.innerWidth < 800 ? (
											<FadeInComponent duration={2000}>
												<SlideLeftComponent duration={1000}>
													<ul style={{textAlign:'left !important'}}>
														{industry?.content?.map((item, i) => {
															return <li key={i}>{item}</li>;
														})}
													</ul>
												</SlideLeftComponent>
											</FadeInComponent>
										) : (
											<ul>
												{industry?.content?.map((item, i) => {
													return <li key={i}>{item}</li>;
												})}
											</ul>
										)}
									</div>
									<button
										className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
										onClick={() => {
											window.open("https://lynkit.in/ContactUs", "_blank");
										}}
									>
										Know More
									</button>
								</div>

								{window.innerWidth < 800 ? (
									<div className={styles.img_container}>
										<img src={industry.img} />
										<div className={styles.title_over_img}>
											<span>{industry?.name}</span>
										</div>
									</div>
								) : (
									<SlideRightComponent duration={1000}>
										<div className={styles.img_container}>
											<img src={industry.img} />
											{/* <div className={styles.title_over_img}>
												<span>{industry?.name}</span>
											</div> */}
										</div>
									</SlideRightComponent>
								)}
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>
		</div>
	);
};

export default Industries;
