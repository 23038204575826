import React from "react";
import mainStyle from "../main.module.css";
import Header from "./Components/Header/Header";
import Heros from "./Components/hero_section";
import QuickGlance from "./Components/QuickGlance";
import Ticker from "./Components/Ticker";
import Features from "./Components/Features";
import Testimonial from "./Components/Testimonials";
import OtherFeature from "./Components/OtherFeature";
import Hardware from "./Components/Hardware";
import Industries from "./Components/Industries";
import OurAwards from "./Components/OurAwards";
import Footer from "./Components/Footer";
import News from "./Components/News";
import IotDevices from "./Components/IotDevices";
import UseCases from "./Components/UseCases";
import Api from "./Components/API";
import Linkedin from "./Components/Linkedin";
// import News from "./Components/News";

const WMS = () => {
	const [selectedFeature, setSelectedFeature] = React.useState(0);
	const [selectedIndustry, setSelectedIndustry] = React.useState(0);

	return (
		<div className={mainStyle.landing_page} style={{ overflowX: "hidden" }} >
			<Header  setSelectedFeature={setSelectedFeature} />
			<div className={mainStyle.section_wrapper} style={{ position: "relative" }}>
				<section id="heros" className={mainStyle.section} style={{ position: "relative" }}>
					<Heros />
				</section>

				<section className={mainStyle.section} style={{ background: "#fff", position: "relative" }}>
					<Ticker />
				</section>

				<section className={mainStyle.section} style={{ background: "#fff", position: "relative" }}>
					<QuickGlance />
				</section>

				<section id="features" className={mainStyle.section} style={{ background: "#fff", position: "relative" }}>
					<Features setCurrent={setSelectedFeature} current={selectedFeature} />
				</section>

				<section className={mainStyle.section} style={{ background: "#fff", position: "relative" }}>
					<Testimonial />
				</section>

				<section id='usecases' className={mainStyle.section} style={{ background: "#fff", position: "relative" }}>
					<UseCases />
				</section>

				<section className={mainStyle.section} style={{ position: "relative" }}>
					<OtherFeature setCurrent={setSelectedFeature} current={selectedFeature} />
				</section>

				<section id="hardware-products" className={mainStyle.section} style={{ position: "relative" }}>
					<Hardware />
				</section>

				<section className={mainStyle.section} style={{ position: "relative" }}>
					<IotDevices />
				</section>

				<section id="api" className={mainStyle.section} style={{ position: "relative" }}>
					<Api />
				</section>

				<section className={mainStyle.section} style={{ position: "relative" }}>
					<Industries setCurrent={setSelectedIndustry} current={selectedIndustry} />
				</section>

				<section className={mainStyle.section} style={{ position: "relative" }}>
					<OurAwards />
				</section>

				<section className={mainStyle.section} style={{ position: "relative" }}>
					<News />
				</section>
				<section
          			className={mainStyle.section}
          			style={{ position: "relative" }}
        		>	
          		<Linkedin />
        		</section> 
        {/* <section className={mainStyle.section} style={{ position: "relative" }}>
					<News />
				</section> */}
				{/*
        <section
          className={mainStyle.section}
          style={{position:'relative', minHeight: "auto"}}
          id="useCases"
        >
          <SupplyChainNew/>
        </section>

        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Reviews />
        </section>
  
      


        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
          id="api"
        >
          <API />
        </section>
        
      
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <LatestNews />
        </section>*/}

			</div>
			<section className={mainStyle.section} style={{ position: "relative" }}>
				<Footer />
			</section>
		</div>
	);
};

export default WMS;
