import React from "react";
import { StyledHeading } from "../../HelperComponents";
import mainStyles from "../../main.module.css";
import styles from "./styles.module.css";
import fleetIcon4 from '../../assets/features/fleetIcon4.svg';
import fleetImg from '../../assets/features/fleet.svg'
import freightInvoiceIcon11 from '../../assets/features/freightInvoiceIcon11.svg'
import freightInvoiceImg from '../../assets/features/freight_invoice.svg'
import eproofIcon10 from '../../assets/features/eproofIcon10.svg'
import eproofImg from '../../assets/features/eproof.svg'
import indentingIcon7 from '../../assets/features/indentingIcon7.svg'
import indentingImg from '../../assets/features/indenting.svg'
const Tab = ({ info, active, onClick }) => {
	return (
		<div className={styles.feature_tab} style={{ background: active ? "#FF7700" : "#FFFFFF" }} onClick={onClick}>
			<img src={info?.icon} />
			<span className={styles.title}>{info?.title}</span>
		</div>
	);
};

const Features = ({ current, setCurrent }) => {
	// const [current, setCurrent] = React.useState(selectedFeature);

	const features = [
			{
				title: "Fleet Management",
				icon: fleetIcon4,
				img: fleetImg,
				text: `Our fleet management solution is designed to control costs, boost productivity, and minimize risks in operations. This module integrates with contracts to ensure accurate billing while maintaining revenue and expense tracking for each vehicle. Service records and fleet expenses are digitized, with the ability to manage documents such as RCs, permits, insurance, and pollution certificates. Notifications for document expiry keep your fleet management processes compliant and streamlined.`,
			},
			{
				title: "Freight Invoices, Expenses, and Ledgers",
				icon: freightInvoiceIcon11,
				img: freightInvoiceImg,
				text: `The Transport Management System (TMS) simplifies financial workflows for transporters and shippers. With features like automated freight bill creation, demurrage fee calculations, and customized payment approval processes, managing expenses and ledgers becomes seamless. This robust platform ensures comprehensive freight management, enabling detailed financial reporting and analysis to support data-driven decision-making.`,
			},
			{
				title: "E-proof of Deliveries",
				icon: eproofIcon10,
				img: eproofImg,
				text: `Say goodbye to lost PODs with our secure and efficient electronic proof-of-delivery (ePOD) system. Features like OTP-based delivery confirmation and tracking ensure accuracy and reliability. The system enables consignees or warehouses to flag delivery exceptions such as damaged goods or short shipments, complete with photos and location tagging. This innovation enhances your TMS software with transparency and accountability.`,
			},
			{
				title: "Indenting and Vehicle Scheduling",
				icon: indentingIcon7,
				img: indentingImg,
				text: `Optimize plant operations with our Transport Management System (TMS) appointment tool. This feature allows smart scheduling by setting rules for vehicle positioning and managing plant entry limits to avoid congestion. With precise fleet management and efficient scheduling, you can enhance productivity and streamline workflows in your operations.`,
			},		
	];

	return (
		<div className={styles.software_features_section}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
                    marginTop: '25px'
				}}
			>
				<StyledHeading text="Software Features" />
			</div>
			<div className={`${styles.features_outer_container} ${styles.large_screens}`}>
				<div className={styles.feature_tabs}>
					{features?.map((feature, i) => {
						return <Tab info={feature} active={i === current ? true : false} onClick={() => setCurrent(i)} key={i} />;
					})}
				</div>

				<div className={styles.feature_content}>
					<div className={styles.text_content_sf}>
						<span>{features[current].title}</span>
						<p>{features[current].text}</p>
					</div>
					<div className={styles.img_content}>
						<img src={features[current].img} />
					</div>
				</div>
				<div className={styles.outline_container}>
					<button
						className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
						onClick={() => {
							window.open("https://lynkit.in/ContactUs", "_blank");
							// window.open("https://lynkit.in/ContactUs", '_self');
						}}
					>
						Get Started
					</button>
				</div>
			</div>
			<div className={`${styles["slider-container"]} ${styles.small_screens}`}>
				<div className={`${styles["slider-wrapper"]}`}>
					{features?.map((feature, i) => {
						return (
							<div className={`${styles["slider-item"]}`}>
								<div className={styles["title-row"]}>
									<img src={feature.icon} />
									<h3>{feature.title}</h3>
								</div>
								<hr style={{ border: "1px solid #ff7700", opacity: 1 }} />
								<p style={{ fontSize: "14px" }}>{feature.text}</p>
								<img src={feature?.img} alt="Geofencing" />
							</div>
						);
					})}
				</div>
			</div>

			<div className={styles.button_small_screen}>
				<button
					className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
					onClick={() => {
						window.open("https://lynkit.in/ContactUs", "_blank");
					}}
				>
					Request Demo
				</button>
			</div>
			<div className={styles.small_screens_button_outliner}></div>
		</div>
	);
};

export default Features;
